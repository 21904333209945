import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import PayInstructor from './PayInstructor';

export default function InstructorPayments() {	
	
  const [open, setOpen] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [instructorPayments, setInstructorPayments] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [instructorId, setInstructorId] = React.useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClosePayment = () => {
    setOpenPayment(false);
    
    getInstructorPayments();
    
  };
  
  const handleClickOpenPayment = () => {
    setOpenPayment(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    'Batch',
    'Payment Date',
    'Amount',   
    'Penalty Deducted',  
    'Comments',      
  ];

  
  
  useEffect(() => {  	

       getInstructorPayments();
       
  }, [addSuccess]);
  
  
  const getInstructorPayments = async () => {
  	
  	 var currentURL = window.location.href;
  	 var urlParts = currentURL.split('/');  	 

  	 var instructorId = urlParts[4];
  	 setInstructorId(instructorId)
  	
    try {
    	
      setOnLoading(true);
      const { data } = await axios.get('/api/admin/getInstructorPaymentRecords?instructorId='+instructorId);
      setOnLoading(false);
      console.log(data);
      setInstructorPayments(data);
      
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
  };
  
  var tableData = [];
  
  for(var i=0;i<instructorPayments.length;i++){
  
      var instructorPayment = [];     
      
      instructorPayment.push(instructorPayments[i].batch.batchName);
      instructorPayment.push(new Date(instructorPayments[i].paymentDate).toDateString());
      instructorPayment.push('₹'+instructorPayments[i].amount);      
      instructorPayment.push('₹'+instructorPayments[i].penaltyDeducted);  
      instructorPayment.push(instructorPayments[i].comments);
      
      
      tableData.push(instructorPayment);   

  }
  

             
       
  
  return (
    <React.Fragment className='studentTable'>
    
     {openPayment && (
        <PayInstructor 
            instructorId={instructorId}
            handleClosePayment={handleClosePayment} 
            open={openPayment} 
            paymentSuccess={() => setPaymentSuccess(!paymentSuccess)} />
      )}

      <div className='AddButtonPosition'>
        <div className='tableHeader'>Payments 
           {instructorPayments.length > 0 &&   
              <span>&nbsp;to <span style={{color:'blue'}}>{instructorPayments[0].instructor.userId.firstName}</span></span>
           }    
           
           <button className='AddButton' 
              style={{fontSize:13, marginLeft:1000}}
              onClick={handleClickOpenPayment}>
              Add New Payment
           </button>
               
        </div>
        
      </div>
      
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
      
    </React.Fragment>
  );
}
