import React, { useState, useEffect } from 'react';
import '../Pages.css';
import ReactDOM from 'react-dom';
import MUIDataTable from 'mui-datatables';
import ViewCourses from './ViewCourses';
import EditCourses from './EditCourses';
import DeleteCourse from './DeleteCourse';
import { CircularProgress } from '@material-ui/core';
import AddCourses from './AddCourses';
import axios from 'axios';
import { Delete } from '@material-ui/icons';

export default function CoursesTable() {
  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [selectedCourse, setSelectedCourse] = React.useState('');
  const [openView, setOpenView] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [courses, setCourses] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenView = () => {
    setOpenView(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const columns = [
    {
      name: 'ID',
      options: {
        display: false,
      },
    },
    'Style',
    'Centers',
    'Instructors',
    'Students',
    'Batches',
    {
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                className='EditTableButton'
                style={{fontSize:11}}
                onClick={() => {
                  setSelectedCourse(tableMeta.rowData);
                  handleClickOpenEdit();
                }}
              >
                View | Edit
              </button>
              <button
                className='deleteIcon'
                style={{marginTop:10}}
                onClick={() => {
                  setSelectedCourse(tableMeta.rowData[0]);
                  setOpenDelete(true);
                }}
              >
                <Delete />
              </button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
  };

  useEffect(() => {
    loadCourses();
  }, [addSuccess]);

  const loadCourses = async () => {
    try {
      setOnLoading(true);
      const { data } = await axios.get('api/courses/getAll');
      console.log('style table Data', data);
      setOnLoading(false);
      setCourses(data.courses);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      // console.log(error.data);
      setOnLoading(false);
    }
  };

  return (
    <React.Fragment>
      <AddCourses handleClose={handleClose} open={open} addSuccess={() => setAddSuccess(!addSuccess)} />
      <ViewCourses handleClose={handleCloseView} open={openView} />
      {openEdit && (
        <EditCourses
          handleClose={handleCloseEdit}
          open={openEdit}
          id={selectedCourse[0]}
          editSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      {openDelete && (
        <DeleteCourse
          handleClose={() => setOpenDelete(false)}
          open={openDelete}
          course={selectedCourse}
          deleteSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      <div className='AddButtonPosition'>
        <div className='tableHeader'>COURSES/STYLES</div>
        <button className='AddButton' onClick={handleClickOpen}>
          ADD STYLE
        </button>
        {/* <button className="AddButton">ADD STYLE</button> */}
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            // title={"Courses / Style Table"}
            data={courses.map((coursesData, i) => {
              return [
                coursesData._id,
                coursesData.courseName,
                coursesData.centers,
                coursesData.instructors,
                coursesData.numStudents,
                coursesData.numBatches,
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
