import React, { useEffect, useState } from 'react';
import '../Pages.css';
import { Multiselect } from 'multiselect-react-dropdown';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Cancel } from '@material-ui/icons';
import { nanoid } from 'nanoid';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


export default function AppBanners(props) {
	
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectStyle, setSelectStyle] = useState([]);
  const [selectCenter, setSelectCenter] = useState([]);
  const [selectBatch, setSelectBatch] = useState([]);
  const [styleData, setStyleData] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [batchData, setbatchData] = useState([]);
  const [image1Input, setImage1Input] = useState('');
  const [image2Input, setImage2Input] = useState('');
  const [image3Input, setImage3Input] = useState('');
  const [image1Uploaded, setImage1Uploaded] = useState('');
  const [image2Uploaded, setImage2Uploaded] = useState('');
  const [image3Uploaded, setImage3Uploaded] = useState('');
  
  
  
  const [addBanners, setAddBanners] = useState({
    banner1Title: '',    
    banner2Title: '',
    banner3Title:'',    
  });
  
  const [onLoading, setOnLoading] = useState(false);

  const { banner1Title, banner2Title, banner3Title } = addBanners;
  
  const onInputChange = (e) => {
    setAddBanners({ ...addBanners, [e.target.name]: e.target.value });
  };

  const handleSelectStyle = (list, item) => {
    setSelectStyle((selectStyle) => [...selectStyle, item._id]);
  };
  
  const handaleSelectStyleDelete = (list, item) => {
    const updateSelectStyle = selectStyle.filter((_id) => _id !== item._id);
    setSelectStyle([...updateSelectStyle]);
  };

  const handleSelectCenter = async (list, item) => {
    setSelectCenter((selectCenter) => [...selectCenter, item]);
    await getAllBatchesByCenters([...selectCenter, item]);
  };
  const handaleSelectCenterDelete = async (list, item) => {  	

    setSelectCenter([...list]);
    console.log(selectCenter);
    await getAllBatchesByCenters([...list.map((c) => c._id)]);
  };

  const handleSelectBatch = (list, item) => {
    setSelectBatch((selectBatch) => [...selectBatch, item._id]);
  };
  const handaleSelectBatchDelete = (list, item) => {
    const updateSelectBatch = selectBatch.filter((_id) => _id !== item._id);
    setSelectBatch([...updateSelectBatch]);
  };

  useEffect(() => {
  	
    loadAppBannersInfo();
  }, []);

  //get all  centers
  const loadAppBannersInfo = async () => {
  	
    try {
    	
      setOnLoading(true);
      const { data } = await axios.get('/api/admin/loadAppBannersInfo');

      setOnLoading(false);      
      
      setAddBanners({banner1Title:data.studentAppBanner1.title, banner2Title:data.studentAppBanner2.title, banner3Title:data.studentAppBanner3.title});
      setImage1Uploaded(data.studentAppBanner1.imageUrl);
      setImage2Uploaded(data.studentAppBanner2.imageUrl);
      setImage3Uploaded(data.studentAppBanner3.imageUrl);      
      

    } catch (error) {
    	
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //get all batchs by centers
  const getAllBatchesByCenters = async (centers) => {
    try {
      const { data } = await axios.post('api/batches/getAllNameByFilters', {
        centers: [...centers],
      });
      setbatchData([...data.batches]);
    } catch (error) {
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //banner images
  const handelBannerImage1InputChange = (e) => {
  	
    const image1 = e.target.files[0];
    const reader1 = new FileReader();
    reader1.readAsDataURL(image1);
    reader1.onloadend = () => {
      setImage1Input(reader1.result);
    };
    setImage1Uploaded(image1);
    
  };
  
  const handelBannerImage2InputChange = (e) => {
  	
    const image1 = e.target.files[0];
    const reader1 = new FileReader();
    reader1.readAsDataURL(image1);
    reader1.onloadend = () => {
      setImage2Input(reader1.result);
    };
    setImage2Uploaded(image1);
  };
  
  
  const handelBannerImage3InputChange = (e) => {
  	
    const image1 = e.target.files[0];
    const reader1 = new FileReader();
    reader1.readAsDataURL(image1);
    reader1.onloadend = () => {
      setImage3Input(reader1.result);
    };
    
    setImage3Uploaded(image1);
  };
  

  const deleteImage1 = () => {
    setImage1Input('');
  };
  
  const deleteImage2 = () => {
    setImage2Input('');
  };
  
  const deleteImage3 = () => {
    setImage3Input('');
  };

  //submit function
  const submitHandler = async (e) => {
  	
    e.preventDefault();
    
    console.log(image1Uploaded)
    console.log(image2Uploaded)
    console.log(image3Uploaded)
    
    const bannersData = {
      banner1Title,
      banner2Title,
      banner3Title,            
    };

    if (
      bannersData.banner1Title &&
      bannersData.banner2Title &&
      bannersData.banner3Title      
    ) {
      try {     
      
       setOnLoading(true);   

        if (image1Input) {
        	
          const imageData = new FormData();         

          
          imageData.append(
            'image',
            image1Uploaded,
            'appbanner1'
          );

          await axios.post(`/api/admin/uploadStudentAppBanner1`, imageData, {
            headers: {
              headers: { 'Content-type': 'application/form-data' },
            },
          });

          bannersData.image1Uploaded = image1Uploaded.name;
        }
        
        
        if (image2Input) {
        	
          const imageData = new FormData();         

          
          imageData.append(
            'image',
            image2Uploaded,
            'appbanner2'
          );

          await axios.post(`/api/admin/uploadStudentAppBanner2`, imageData, {
            headers: {
              headers: { 'Content-type': 'application/form-data' },
            },
          });

          bannersData.image2Uploaded = image2Uploaded.name;
        }
        
        
        if (image3Input) {
        	
          const imageData = new FormData();         

          
          imageData.append(
            'image',
            image3Uploaded,
            'appbanner3'
          );

          await axios.post(`/api/admin/uploadStudentAppBanner3`, imageData, {
            headers: {
              headers: { 'Content-type': 'application/form-data' },
            },
          });

          bannersData.image3Uploaded = image3Uploaded.name;
        }
        
        

        
        
        const { data } = await axios.post('api/admin/updateStudentAppBanners', bannersData);
        
        
        setOnLoading(false);
        setSuccessSnackbar(true);
        console.log('Batch Data', data);
        
        window.location.reload();
        
      } catch (error) {
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please fill all require fields');
      setErrorSnackbar(true);
    }
  };

  return (
  
   <React.Fragment>
    <div style={{padding:50}}>
      
          <form onSubmit={(e) => submitHandler(e)} autocomplete='off'>
          
            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Banner 1 Title*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='Banner 1 Title'
                  name='banner1Title'
                  value={banner1Title}
                  onChange={(e) => onInputChange(e)}
                />
              </div>  

              <div>
              
                <div className='InstructorTopContent'>
                
              {!image1Input ? (
                <div className='overviewImgBanner uk-text-center'>
                  <input
                    type='file'
                    accept='image/jpeg'
                    id='upload-image'
                    onChange={(e) => handelBannerImage1InputChange(e)}
                    hidden
                  />
                  
                  <img src={image1Uploaded} width="100" height="100"/>
                  
                  <label className='upload-image-box' for='upload-image'>
                    <i class='far fa-image fa-7x'></i>
                    <br></br>
                    <p>Upload Banner 1 Image (Only JPG)</p>
                  </label>
                </div>
              ) : (
                <div className='image-container'>
                  <span>
                    <Cancel onClick={() => deleteImage1()} />
                  </span>
                  <img alt='' className='' src={image1Input}></img>
                </div>
              )}
            </div>
              </div>
            </div>



           <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Banner 2 Title*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='Banner 2 Title'
                  name='banner2Title'
                  value={banner2Title}
                  onChange={(e) => onInputChange(e)}
                />
              </div>  

              <div>
                <div className='InstructorTopContent'>
              {!image2Input ? (
                <div className='overviewImgBanner uk-text-center'>
                  <input
                    type='file'
                    accept='image/jpeg'
                    id='upload-image'
                    onChange={(e) => handelBannerImage2InputChange(e)}
                    hidden
                  />
                  
                  <img src={image2Uploaded} width="100" height="100"/>
                  
                  <label className='upload-image-box' for='upload-image'>
                    <i class='far fa-image fa-7x'></i>
                    <br></br>
                    <p>Upload Banner 2 Image (Only JPG)</p>
                  </label>
                </div>
              ) : (
                <div className='image-container'>
                  <span>
                    <Cancel onClick={() => deleteImage2()} />
                  </span>
                  <img alt='' className='' src={image2Input}></img>
                </div>
              )}
            </div>
              </div>
            </div>




            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Banner 3 Title*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='Banner 3 Title'
                  name='banner3Title'
                  value={banner3Title}
                  onChange={(e) => onInputChange(e)}
                />
              </div>  

              <div>
                <div className='InstructorTopContent'>
              {!image3Input ? (
                <div className='overviewImgBanner uk-text-center'>
                  <input
                    type='file'
                    accept='image/jpeg'
                    id='upload-image'
                    onChange={(e) => handelBannerImage3InputChange(e)}
                    hidden
                  />
                  
                  <img src={image3Uploaded} width="100" height="100"/>


                  <label className='upload-image-box' for='upload-image'>
                    <i class='far fa-image fa-7x'></i>
                    <br></br>
                    <p>Upload Banner 3 Image (Only JPG)</p>
                  </label>
                </div>
              ) : (
                <div className='image-container'>
                  <span>
                    <Cancel onClick={() => deleteImage3()} />
                  </span>
                  <img alt='' className='' src={image3Input}></img>
                </div>
              )}
            </div>
              </div>
            </div>
          
           

            <div className='instructorButton'>
             
              <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
            </div>
          </form>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              App Banners Updated
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage.substring(0, 50)}
            </Alert>
          </Snackbar>
        
    </div>
    </React.Fragment>
  );
}
