import React, { useEffect, useState } from 'react';
import '../Pages.css';
import ReactDOM from 'react-dom';
import MUIDataTable from 'mui-datatables';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { CircularProgress } from '@material-ui/core';
import AddCoupon from './AddCoupon';
import axios from 'axios';
import MailCouponCodeModal from './MailCouponCodeModal';
import DeleteCoupon from './DeletedCoupon';

export default function CouponTable() {
  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedCupon, setSelectedCupon] = useState('');
  const [open1, setOpen1] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    'Coupon Code',
    'Amount',
    'Status',
    'Created',
    {
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                className='ViewTableButton'
                style={{display:'none'}}
                onClick={() => {
                  setSelectedCupon(tableMeta.rowData[0]);
                  setOpen1(true);
                }}
              >
                View
              </button>
              
              <button
                className='ViewTableButton'
                style={{ backgroundColor: 'red', marginLeft: '.4em' }}
                onClick={() => {
                  setSelectedCupon(tableMeta.rowData[0]);
                  setOpenDelete(true);
                }}
              >
                Delete
              </button>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
  };
  useEffect(() => {
    loadCoupons();
  }, [addSuccess]);

  const loadCoupons = async () => {
    try {
      console.log('coupon datasss');
      setOnLoading(true);
      const { data } = await axios.get('api/coupons/getAll');
      setOnLoading(false);
      setCouponData(data.coupons);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      // console.log(error.data);
      setOnLoading(false);
    }
  };
  return (
    <React.Fragment>
      {open && (
        <AddCoupon
          handleClose={handleClose}
          open={open}
          addSuccess={() => {
            setOpen1(true);
            setAddSuccess(!addSuccess);
          }}
          selectedCoupon={(couponCode) => setSelectedCupon(couponCode)}
        />
      )}
      {/* {open1 && (
        <MailCouponCodeModal
          handleClose={() => setOpen1(false)}
          open={open1}
          couponCode={selectedCupon}
          addSuccess={() => setAddSuccess(!addSuccess)}
        />
      )} */}
      {openDelete && (
        <DeleteCoupon
          handleClose={() => setOpenDelete(false)}
          open={openDelete}
          coupon={selectedCupon}
          deleteSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      <div className='AddButtonPosition'>
        <div className='tableHeader'>COUPONS</div>
        <button className='AddButton' onClick={handleClickOpen}>
          Add Coupon
        </button>
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={couponData.map((coupon) => {
              return [
                coupon.couponCode,
                coupon.couponAmount,
                coupon.isApplied ? 'Expired' : 'Active',
                coupon.createdAt.substring(0, 10),
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
