import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
// import PaymentModal from "./PaymentModal";
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';

export default function PaymentsTable() {
  
  const [transactionData, setTransactionData] = useState([]);
  const [onLoading, setOnLoading] = useState(false);  

  const columns = [
    'Student Name',
    'Transaction Type',
    'Enrollment Fees',
    'Discount',
    'Total Amount',
    'Paid On',
    'Status',
  ];
  useEffect(() => {
    loadTransactions();
  }, []);

  const loadTransactions = async () => {
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/enrollments/transactions/all');
      setOnLoading(false);
      setTransactionData(data.transactions);
    } catch (error) {
      setOnLoading(false);      
    }
  };

  return (
    <React.Fragment className='studentTable'>

      <div className='AddButtonPosition'>
        <div className='tableHeader'>PAYMENTS</div>        
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '-1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={transactionData.map((transaction) => {
              return [
                  transaction.enrollmentId !==null &&
                  transaction.enrollmentId.student && transaction.enrollmentId.student.userId &&
                  transaction.enrollmentId.student && transaction.enrollmentId.student.userId.firstName,
                  transaction.transactionType === 'enroll'
                    ? 'Enrollment'
                    : transaction.transactionType === 'renew'
                    ? 'Renew-Enrollment'
                    : transaction.transactionType,
                 
                transaction.enrollmentId !==null &&    
                transaction.enrollmentId.batch && '₹'+transaction.enrollmentId.batch.fees,
                '₹'+transaction.discountAmount,
                '₹'+transaction.transactionAmount,
                transaction && transaction.createdAt.substring(0, 10),
                transaction.isPaid === true ? 'Success' : 'Failed',
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, 
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
