import React, { useEffect, useState } from 'react';
import '../Pages.css';
import PieChartIcon from '@material-ui/icons/PieChart';
import MultilineChartSharpIcon from '@material-ui/icons/MultilineChartSharp';
import StudentImg from '../../Assets/Image/student.png';
import TeacherImg from '../../Assets/Image/teacher.png';
import CentersImg from '../../Assets/Image/institute.png';
import CoursesImg from '../../Assets/Image/courses.jpg';
import BarchartImg from '../../Assets/Image/barchat.png';
import circularChartImg from '../../Assets/Image/circularChart.png';
import axios from 'axios';

import { CircularProgress } from '@material-ui/core';

const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setsetDashboardData] = useState('');

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    try {
      console.log('instructor datasss');
      setLoading(true);
      const { data } = await axios.get('/api/admin/dashboard');
      console.log('instructor data', data.instructors);
      setLoading(false);
      setsetDashboardData(data);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setLoading(false);
    }
  };

  return loading ? (
    <CircularProgress style={{ position: 'absolute', left: '55%', top: '30%' }} />
  ) : (
    <div className='Dashboard'>
      <div className='DashboardTopContainer'>
        <div className='DashboardContainer'>
          <img src={StudentImg} style={{ width: '7vw' }} alt='...' />
          <div>
            <div className='dashboardTotal'>Total Students</div>
            <div className='dashboardTotalNumber'>{dashboardData.numStudents}</div>
          </div>
        </div>
        <div className='DashboardContainer'>
          <img src={TeacherImg} style={{ height: '19vh' }} alt='...' />
          <div>
            <div className='dashboardTotal'>Total Instructors</div>
            <div className='dashboardTotalNumber'>{dashboardData.numInstructors}</div>
          </div>
        </div>
        <div className='DashboardContainer'>
          <img src={CentersImg} style={{ width: '7vw' }} alt='...' />
          <div>
            <div className='dashboardTotal'>Total Centers</div>
            <div className='dashboardTotalNumber'>{dashboardData.numCenters}</div>
          </div>
        </div>
        <div className='DashboardContainer'>
          <img src={CoursesImg} style={{ width: '7vw' }} alt='...' />
          <div>
            <div className='dashboardTotal'>Total Courses</div>
            <div className='dashboardTotalNumber'>{dashboardData.numCourses}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// <div className="DashboardMiddleContainer">
// {/* <div className="DashboaedGraph1"><PieChartIcon style={{ fontSize: 200 }} /></div> */}
// <div className="DashboaedGraph1">
//     <img style={{width: "55%"}} src={circularChartImg} alt="..." />
// </div>
// {/* <div className="DashboaedGraph2"><MultilineChartSharpIcon style={{ fontSize: 200 }} /></div> */}
// <div className="DashboaedGraph2">
//     <img style={{width: "78%"}} src={BarchartImg} alt="..."/>
// </div>
// </div>
// <div className="DashboardBottomContainer">
// <div className="DashboaedUser"> User</div>
// <div className="DashboaedUser">Notification</div>
// </div>

export default Index;
