import React from 'react'
// import CustomScrollbars from 'react-custom-scrollbars'

const Scrollbars = ({children, className, ...rest}) => {
    return (
        <div className={`overflow-auto ${className}`} {...rest}>
            {children}
        </div>
    )
}

export default Scrollbars
