import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Tooltip } from '@material-ui/core';


export default function StudentAttendance() {	
	
  const [open, setOpen] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [studentAttendances, setStudentAttendances] = useState([]);
  const [classes, setClasses] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [classId, setClassId] = useState('');
  const [studentId, setStudentId] = useState('');
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  
  
  const Transition = React.forwardRef(function Transition(props, ref) {
     return <Slide direction='up' ref={ref} {...props} />;
  });

  const columns = [
    'Batch',
    'Class',
    'Attendance Status',   
    'Marked At',      
  ];

  
  
  useEffect(() => {  
  
       var currentURL = window.location.href;
  	    var urlParts = currentURL.split('/');  	 

  	    var studentId1 = urlParts[4];	
  	    
  	    setStudentId(studentId1);

       getStudentAttendances();
       
  }, [addSuccess]);
  
  // Open mark attendance modal
  const openMarkAttendanceModal = async () => { 
      
     console.log('yess')
     setIsDialogOpen(true);
  }
  
  const handleClose = () => {
      setIsDialogOpen(false);
  };
  
  
  // Select a class to mark attendance of the current student of
  const onSelectChange = (event) => {
      
      setClassId(event.target.value)
  };
  
  
  // Mark a student as present in a class
  const markAsPresent = async() => {
      const { data } = await axios.post('/api/users/instructors/markStudentAttendance', { "classId":classId, "studentId":studentId });
      setIsDialogOpen(false);          
      getStudentAttendances();
  };

  
  
  const getStudentAttendances = async () => {
  	
  	 var currentURL = window.location.href;
  	 var urlParts = currentURL.split('/');  	 

  	 var studentId = urlParts[4];
  	
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/users/students/getAttendance?id='+studentId);
      setOnLoading(false);
      console.log(data.attendances);
      setStudentAttendances(data.attendances);
      setClasses(data.classes);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
  };
  
  var tableData = [];
  
  for(var i=0;i<studentAttendances.length;i++){
  
      var attendance = [];     
      
      attendance.push(studentAttendances[i].class.batch.batchName);
      attendance.push(studentAttendances[i].class.date + '-'+ studentAttendances[i].class.startTime);      
      
      
      attendance.push(studentAttendances[i].attendanceStatus);      
      attendance.push(new Date(studentAttendances[i].createdAt).toDateString() + '-' +new Date(studentAttendances[i].createdAt).toTimeString());      
      tableData.push(attendance);
  }
  

        
  return (
    <React.Fragment className='studentTable'>

      <div className='EditButtonPosition' style={{display:'flex', flexDirection:'row', marginTop:10, marginLeft:10}}>
        <div className='tableHeader'>Attendance 
           {studentAttendances.length > 0 &&   
               <span>&nbsp;of <span style={{color:'blue'}}>{studentAttendances[0].student.userId.firstName}</span></span>
           }             
                 
        </div>
        
        <button className="EditTableButton pull-right" 
             style={{width:400, padding:5, fontSize:13, marginTop:10}} onClick={() => openMarkAttendanceModal() }>
              Mark Attendance of a Student
        </button> 
        
       
      </div>
      
       
      
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
        
      {isDialogOpen &&
        <Dialog
           open={isDialogOpen}
           TransitionComponent={Transition}
           keepMounted
           onClose={handleClose}
           aria-labelledby='alert-dialog-slide-title'
           aria-describedby='alert-dialog-slide-description'
        >
           <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
             Marking Attendance
           </DialogTitle>
           
          <DialogContent>
          {studentAttendances.length > 0 &&
          
           <div>
               <label style={{fontSize:13, marginTop:50}}>Select Class to Mark Attendance of {studentAttendances[0].student.userId.firstName}</label>          
           
               <select 
                   className="form-control" 
                   onChange={onSelectChange}
                   value={classId}
                   style={{marginTop:15}}>
           
                   {classes.map((theClass, index) => (
                       <option value={theClass._id}>
                          {theClass.batch.batchName}- {theClass.date}
                       </option>
                   ))}
              
               </select>
           
           </div>
           
          } 
           
           <button className="ViewTableButton" style={{marginTop:15, width:300, marginBottom:50}} onClick={() => markAsPresent() }>
               Mark as Present
           </button>
          
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Attendance Marked
            </Alert>
          </Snackbar>
          
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage.substring(0, 50)}
            </Alert>
          </Snackbar>
          
        </DialogContent>
      </Dialog>
   }
      </div>
      
    </React.Fragment>
  );
}
