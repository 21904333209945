import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
// import PaymentModal from "./PaymentModal";
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AddEnrollment from './AddEnrollment';
import DeleteEnrollment from './deleteEnrollment';

export default function Enrollments() {
	
  const [open, setOpen] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [enrollments, setEnrollments] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedEnrollment, setSelectedEnrollment] = React.useState('');
  
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          backgroundColor: "#ff0000"
        }
      }
    }
  })

  
  
  
  const history = useHistory();


  const columns = [
  
    'Student',
    'Style',
    'Batch',  
    'Reg Fees', 
    'Fees',   
     {
          label: "Action",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <button className="EditTableButton" 
                    onClick={() => {
                      setSelectedEnrollment(value);
                      setOpenDelete(true);
                    }}>
                    Delete
                  </button>
              )
          }
         }
     },
     
    
     
    
  ];

  
  useEffect(() => {
    loadEnrollments();
  }, [addSuccess]);

  const loadEnrollments = async () => {
  	
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/admin/getAllEnrollments');
      setOnLoading(false);
      console.log(data);
      setEnrollments(data);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
    }
  };

  return (
    <React.Fragment className='studentTable'>
    
      {open && (
        <AddEnrollment handleClose={handleClose} open={open} addSuccess={() => setAddSuccess(!addSuccess)} />
      )}

      <div className='AddButtonPosition'>
        <div className='tableHeader'>ENROLLMENTS</div>
        <button className='AddButton' style={{width:300}} onClick={handleClickOpen}>
          Add an Enrollment
        </button> 
        
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '-1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
        


          <MUIDataTable
            data={enrollments.map((enrollment) => {
            	
              return [
              
                enrollment.student.userId && enrollment.student.userId.firstName,
                
                enrollment.course &&
                enrollment.course.courseName,
                
                enrollment.batch &&
                enrollment.batch.batchName,
                
                enrollment.batch &&
                '₹'+enrollment.batch.registrationFees,
                
                enrollment.batch &&
                '₹'+enrollment.batch.fees,               
                
                enrollment._id,              

              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />   


        )}
        
        {openDelete && (
        <DeleteEnrollment
          handleClose={() => setOpenDelete(false)}
          open={openDelete}
          enrollment={selectedEnrollment}
          deleteSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      
      
      </div>
    </React.Fragment>
  );
}
