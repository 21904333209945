import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
// import PaymentModal from "./PaymentModal";
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useHistory } from "react-router-dom";


export default function DemoRequests() {
	
  const [open, setOpen] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [demoRequests, setDemoRequests] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const history = useHistory();


  const columns = [
    'Student',
    'Requested for Batch',
    'Requested for Date',
    'Requested On',     
  ];

  
  useEffect(() => {
  	
    loadDemoRequests();
    
  }, [addSuccess]);

  const loadDemoRequests = async () => {
  	
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/users/students/getDemoRequests');
      setOnLoading(false);
      console.log(data.demoRequests);
      setDemoRequests(data.demoRequests);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
  };

  return (
    <React.Fragment className='studentTable'>

      <div className='AddButtonPosition'>
      
        <div className='tableHeader'>DEMO REQUESTS</div>
        
      </div>
      
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '-1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={demoRequests.map((demoRequest) => {
              return [
                demoRequest.student.userId && (demoRequest.student.userId.firstName + ' (' + demoRequest.student.userId.phoneNumber + ')'),
                demoRequest.batch && demoRequest.batch.batchName,
                demoRequest.date && demoRequest.date,
                demoRequest.createdAt && 
                new Date(demoRequest.createdAt).toDateString() + ' - ' +new Date(demoRequest.createdAt).toLocaleTimeString()+' IST',
                
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
