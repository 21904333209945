import React, { useEffect, useState } from 'react';
import '../Pages.css';
import { Multiselect } from 'multiselect-react-dropdown';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Cancel } from '@material-ui/icons';
import { nanoid } from 'nanoid';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function PayInstructor(props) {
	
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');  
  const [selectClass, setSelectClass] = useState([]);
  
  const [attendances, setAttendances] = useState([]); 
  const [classes, setClasses] = useState([]); 
  const [batches, setBatches] = useState([]); 
  const [selectedClassId, setSelectedClassId] = useState(''); 
  const [selectedBatchId, setSelectedBatchId] = useState(''); 
  
  const [totalAgreed, setTotalAgreed] = useState(0); 
  const [totalPenalty, setTotalPenalty] = useState(0); 
  const [totalPayable, setTotalPayable] = useState(0); 
  const [selectedMonth, setSelectedMonth] = useState('all'); 
  
  const [instructorId, setInstructorId] = useState('');   
    
  
  const [payInstructor, setPayInstructor] = useState({
  	
    instructor: '',    
    batch: '',
    amount: '',
    penaltyDeducted: '',
    month:'all',
    comments:''    
  });
  
  const [onLoading, setOnLoading] = useState(false);

  const { instructor, batch, amount, penaltyDeducted, month, comments } = payInstructor;
  
  const onInputChange = (e) => {
  	
    setPayInstructor({ ...payInstructor, [e.target.name]: e.target.value });
  };
 

  const handleSelectClass = (list, item) => {
    setSelectClass((selectClass) => [...selectClass, item._id]);
  };  
  

  useEffect(() => {
    loadInstructorClassesWithAttendance();
  }, []);
  
  // Select a class to mark attendance of the current student of
  const onSelectChange = (event) => {      
      setSelectedBatchId(event.target.value)
  };
  
  const onSelectMonthChange = (event) => {  
      
      setSelectedMonth(event.target.value);
      
  };
  
  

  //get all  centers
  const loadInstructorClassesWithAttendance = async () => {
  	
    try {
      setOnLoading(true);
      
      const { data } = await axios.get('/api/admin/getInstructorAttendance?id='+props.instructorId);

      setOnLoading(false);
      setAttendances(data.attendances);
      setClasses(data.classes);      
      
      var theBatchIds = [];
      var theBatches = [];
      
      for(var i=0;i<data.classes.length;i++){
      	
      	  for(var j=0;j<data.classes.length;j++){
      	  	
      	  	   if(!theBatchIds.includes(data.classes[j].batch._id)){
                   theBatchIds.push(data.classes[j].batch._id);   
                   theBatches.push(data.classes[j].batch);	  	   
      	  	   }      	  	   
      	  }               
      }
      
      
      setBatches(theBatches);
      
      if(theBatches.length>0){
         setSelectedBatchId(theBatches[0]._id)
      }

    } catch (error) {
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };
  
  
  
  function isDateUnderSelectedMonth(date) {   	

    	
    	var splitArray = date.split("-");
    	
    	var month = splitArray[1];   	
    	
    	if(selectedMonth == 'all'){  
    	   return true;
    	   
    	}else{
         if(month == selectedMonth) {
            return true;            
         }else{
            return false;           
         }   	
    	}   	   	
    	
  }
  

 

  //submit function
  const payInstructorHandler = async (e) => {
  	
    e.preventDefault();
    
    const instructorPayment = {
      instructor:props.instructorId, batch:selectedBatchId, amount, penaltyDeducted, month, comments  
    };
    

    console.log('lalal'+JSON.stringify(instructorPayment))

    if (
      instructorPayment.instructor &&
      instructorPayment.batch &&
      instructorPayment.amount &&
      instructorPayment.penaltyDeducted &&      
      instructorPayment.month &&
      instructorPayment.comments       
    ) {
    	
      try {        

        setOnLoading(true);
        const { data } = await axios.post('api/admin/addInstructorPaymentRecord', instructorPayment);
        
        setTimeout(() => {
          props.handleClosePayment();
          //props.addSuccess();
        }, 2000);
        
        setOnLoading(false);
        setSuccessSnackbar(true);

      } catch (error) {
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please fill all require fields');
      setErrorSnackbar(true);
    }
  };
  
  

  var theTotalAgreed = 0;
  var theTotalPenalty = 0;
  var theTotalPayable = 0;
  
  
  // This is added to ensure the re-rendering happens when selected month changes
  var theAttendances = [];  
  for(var i=0;i<attendances.length;i++){
  	  	
  	  	
  	if(attendances[i].class){  	
  	
  	    if(isDateUnderSelectedMonth(attendances[i].class.date) && (selectedBatchId == attendances[i].class.batch._id)){   	            

           theAttendances.push(attendances[i]);
           
           theTotalAgreed = theTotalAgreed + attendances[i].class.batch.paymentToInstructor;
           
           if(attendances[i].attendanceStatus == 'absent'){
              theTotalPenalty = theTotalPenalty + attendances[i].class.batch.instructorPenaltyPerAbsence;   
              theTotalPayable = theTotalPayable + (attendances[i].class.batch.theTotalAgreed - attendances[i].class.batch.instructorPenaltyPerAbsence);         
           }else {           
              theTotalPayable = theTotalPayable + attendances[i].class.batch.paymentToInstructor;             
           }         
           
       }
    }
    
  }
  
  

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClosePayment}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Adding payment record
        </DialogTitle>
        <DialogContent>
        
          <form onSubmit={(e) => payInstructorHandler(e)} autocomplete='off'>            

          <div>

            <div className='InstructorTopContent'>    
            
             <div style={{flexDirection:'column'}}>   
               <label style={{fontSize:13, marginTop:50}}>Select Batch:</label>                  
               <select 
                   className="form-control" 
                   onChange={onSelectChange}
                   value={selectedBatchId}
                   style={{marginTop:15}}>
           
                   {batches.map((batch, index) => (
                       <option value={batch._id}>
                          {batch.batchName}
                       </option>
                   ))}
              
               </select>    
             </div>  
               
             <div style={{flexDirection:'column'}}>  
               <label style={{fontSize:13, marginTop:50}}>Select Month:</label>               
               <select 
                   className="form-control" 
                   onChange={onSelectMonthChange}
                   value={selectedMonth}
                   style={{marginTop:15}}>  
                       <option value="all">All</option> 
                       <option value="01">Jan 2021</option>
                       <option value="02">Feb 2021</option>
                       <option value="03">Mar 2021</option>
                       <option value="04">Apr 2021</option>
                       <option value="05">May 2021</option>
                       <option value="06">Jun 2021</option>
                       <option value="07">Jul 2021</option>
                       <option value="08">Aug 2021</option>
                       <option value="09">Sep 2021</option>
                       <option value="10">Oct 2021</option>
                       <option value="11">Nov 2021</option>
                       <option value="12">Dec 2021</option>               
               </select>    
             </div>               
                
            </div>          
            

           
            </div>
            

            <div style={{fontSize:12, marginTop:20}}>

             <p style={{fontWeight:'bold', display:'none'}}>Select the classes for which the payments are being made</p>
             
             {theAttendances.map((attendance) => (   
             
               
               <div key={attendance}>       

                 
                 {attendance.class.batch._id == selectedBatchId &&  
                 
                 <div>
                 

                 <div>        
                  

                  <input type="checkbox" name="classes[]" id={attendance.class._id}/>
                  <label for={attendance.class._id} style={{marginLeft:5}}> 
                  
                      <span>Class of {attendance.class.date} - </span>
                      <span>{attendance.attendanceStatus} - </span>
                      <span>Agreed ₹{attendance.class.batch.paymentToInstructor} - </span>
                    
                    {attendance.attendanceStatus == 'Absent' || attendance.attendanceStatus == 'Late Present' &&                   
                      <span>Penalty ₹{attendance.class.batch.instructorPenaltyPerAbsence}- </span>  
                    }             
                                   
                    
                    {attendance.attendanceStatus == 'Absent' || attendance.attendanceStatus == 'Late Present' &&                     
                       <span>Payable ₹{attendance.class.batch.paymentToInstructor - attendance.class.batch.instructorPenaltyPerAbsence}</span>
                    }
                    
                    {attendance.attendanceStatus == 'Present' &&
                      
                       <span>Payable ₹{attendance.class.batch.paymentToInstructor}</span>
                    
                    }                     
                    
                    
                  </label>
                  

                  
                 </div> 
                 
                 </div>
                  
                 }
                 
               </div>  
               

                 
             ))}
             
             </div>           
             

               <h3 style={{marginTop:10}}>Total agreed : ₹{theTotalAgreed}</h3> 
               <h3>Total Penalty : ₹{theTotalPenalty}</h3>
               <h3>Total Payable : ₹{theTotalPayable}</h3> 
            
            <div className='InstructorTopContent' style={{marginTop:20}}>
              <div>
                <p className='InstructorLable'>Amount*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='Paying Amount'
                  name='amount'
                  value={payInstructor.amount}
                  onChange={(e) => onInputChange(e)}
                />
              </div>

              <div>
                <p className='InstructorLable'>Penalty Deducted*</p>
                <input
                  className='InstructorName'
                  type='text'
                  placeholder='Deducted Penalty'
                  style={{ width: '15vw' }}
                  name='penaltyDeducted'
                  value={payInstructor.penaltyDeducted}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>     
            
            
             <div className='InstructorTopContent' style={{marginTop:20, width:'100%'}}>
                

                <textarea
                  className='InstructorName'
                  type='text'
                  placeholder='Comments'
                  style={{ width: '100%' }}
                  name='comments'
                  value={payInstructor.comments}
                  onChange={(e) => onInputChange(e)}
                />
                
             </div>
            
              

            <div className='instructorButton'>
              <button className='InstructorCancle' onClick={props.handleClosePayment}>
                Cancel
              </button>
              <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
            </div>
          </form>
          
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Payment Record Added
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage.substring(0, 50)}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
}
