import React, { useEffect, useState } from 'react';
import '../Pages.css';
import { Multiselect } from 'multiselect-react-dropdown';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import TimePicker from 'react-time-picker';


let daysData = [
  { days: 'Monday' },
  { days: 'Tuesday' },
  { days: 'Wednesday' },
  { days: 'Thursday' },
  { days: 'Friday' },
  { days: 'Saturday' },
  { days: 'Sunday' },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AddBatches(props) {
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [batchDays, setBatchDays] = useState([]);
  const [batchStyles, setBatchStyles] = useState([]);
  const [batchInstructors, setBatchInstructors] = useState([]);
  const [instructorData, setInstructorData] = useState([]);
  const [centersData, setCentersData] = useState([]);
  const [styleData, setStyleData] = useState([]);
  
  const [addBatches, setAddBatches] = useState({
    name: '',
    startTime: '',
    endTime: '',
    center: centersData[0],
    registrationFees: '',
    occupancy: '',
    numClasses: '',
    numFreezeLimit: '',
    fees: '',
    isDemo: true,
    hasDemo: false,
    instructorPenalty: '',
    paymentToInstructor:'',
    isSuspended: false,
  });
  const [onLoading, setOnLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    name,
    startTime,
    endTime,
    center,
    registrationFees,
    occupancy,
    numClasses,
    numFreezeLimit,
    fees,
    isDemo,
    hasDemo,
    instructorPenalty,
    paymentToInstructor,
    isSuspended,
  } = addBatches;
  
  const onInputChange = (e) => {
    setAddBatches({ ...addBatches, [e.target.name]: e.target.value });
  };

  const handleDaysChange = (list, item) => {
    setBatchDays([...list]);
  };
  const handaleDaysDelete = (list, item) => {
    setBatchDays([...list]);
  };

  const handleCenterChange = async (e) => {
    setAddBatches({ ...addBatches, center: e.target.value });

    await getAllCoursesByCenter(e.target.value);
  };

  const handleStyleChange = async (list, item) => {
    const updatedStyles = list.map((s) => s._id);
    setBatchStyles([...list]);

    setBatchInstructors([]);
    setInstructorData([]);
    await getAllInstructors(addBatches.center, updatedStyles);
  };
  const handaleStyleDelete = async (list, item) => {
    const updatedStyles = list.map((s) => s._id);
    setBatchStyles([...list]);

    setBatchInstructors([]);
    setInstructorData([]);
    await getAllInstructors(addBatches.center, updatedStyles);
  };
  const handleInstructorChange = (list, item) => {
    setBatchInstructors((batchInstructors) => [...batchInstructors, item]);
  };
  const handaleInstructorDelete = (list, item) => {
    setBatchInstructors([...list]);
  };

  useEffect(() => {
    loadBatchData();
    loadCenters();
  }, []);

  //get the course
  const loadBatchData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/batches/getABatch?id=${props.id}`);
      setLoading(false);
      if (data) {
        setAddBatches({
          name: data.batch.batchName,
          startTime: data.batch.startTime,
          endTime: data.batch.endTime,
          center: data.batch.center._id,
          registrationFees: data.batch.registrationFees,
          numClasses: data.batch.numClasses,
          numFreezeLimit: data.batch.classFreezeLimit,
          occupancy: data.batch.maxStudents,
          fees: data.batch.fees,
          isDemo: data.batch.isDemo,
          hasDemo: data.batch.hasDemo,
          instructorPenalty: data.batch.instructorPenaltyPerAbsence,
          paymentToInstructor: data.batch.paymentToInstructor,
          isSuspended: data.batch.isSuspended,
        });
        setBatchInstructors([
          ...data.batch.instructors.map((i) => {
            return {
              _id: i._id,
              name: i.userId.firstName,
            };
          }),
        ]);
        setBatchStyles(data.batch.courses);
        setBatchDays([
          ...data.batch.batchDays.map((d) => {
            return {
              days: d.toString(),
            };
          }),
        ]);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //get all  courses by center
  const getAllCoursesByCenter = async (center) => {
  	
    try {
      setOnLoading(true);
      const { data } = await axios.get(`api/courses/getAllByCenter?id=${center}`);     

      setOnLoading(false);
      setStyleData([...data.courses]);
    } catch (error) {
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //get all  courses by center
  const getAllInstructors = async (center, courses) => {
    try {
      setOnLoading(true);
      const { data } = await axios.post('api/users/instructors/getAllNameByFilters', { center, courses });
      setOnLoading(false);
      setInstructorData([...data.instructors]);
    } catch (error) {
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //get all  centers
  const loadCenters = async () => {
    try {
      setOnLoading(true);
      const { data: centerRecived } = await axios.get('/api/centers/getAllNameAddress');
      setCentersData([...centerRecived.centers]);
      if (centerRecived.length > 0) {
        const { data } = await axios.get(`api/courses/getAllByCenter?id=${centerRecived[0]._id}`);

        setStyleData([...data.courses]);
      }
      setOnLoading(false);
    } catch (error) {
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //submit function

  const editBatchesHandler = async (e) => {
  	
    e.preventDefault();
    
    const batchData = {
      batchName: name,
      startTime,
      endTime,
      center,
      registrationFees,
      occupancy,
      fees,
      numClasses,
      classFreezeLimit: numFreezeLimit,
      isDemo: false,
      hasDemo: hasDemo && hasDemo === 'true' ? true : false,
      instructorPenalty,
      paymentToInstructor,
      isSuspended: isSuspended && isSuspended === 'true' ? true : false,
      batchDays: [...batchDays.map((d) => d.days)],
      instructors: [...batchInstructors.map((i) => i._id)],
      courses: [...batchStyles.map((s) => s._id)],
    };
    console.log(batchData);

    if (
      batchData.batchName &&
      batchData.startTime &&
      batchData.endTime &&
      batchData.registrationFees !== null &&
      batchData.occupancy &&
      batchData.center &&
      batchData.fees &&
      batchData.numClasses &&
      batchData.classFreezeLimit !== null &&
      batchData.instructorPenalty !== null &&
      batchData.paymentToInstructor !== null &&
      batchData.batchDays.length > 0 &&
      batchData.instructors.length > 0 &&
      batchData.courses.length > 0
    ) {
      console.log(batchData.center);
      try {
        if (batchData.startTime >= batchData.endTime) {
          throw new Error('Batch start time cannot be greater than end time');
        }

        setOnLoading(true);

        const { data } = await axios.put(`api/batches/edit?id=${props.id}`, batchData);
        setTimeout(() => {
          props.handleClose();
          props.editSuccess();
        }, 2000);
        setOnLoading(false);
        setSuccessSnackbar(true);
      } catch (error) {
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please fill all require fields');
      setErrorSnackbar(true);
    }
  };
  
  const onStartTimeChange = async (value) => {
  	  setAddBatches({ ...addBatches, 'startTime': value })
  }
  
  const onEndTimeChange = async (value) => {
  	  setAddBatches({ ...addBatches, 'endTime': value })
  }
  

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Edit Batch
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => editBatchesHandler(e)}>
            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Name*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='Enter batch name'
                  name='name'
                  value={name}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div>
                <p className='InstructorLable'>Time*</p>
                <div
                  style={{
                    display: 'flex',
                    width: '15vw',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection:'column'
                  }}
                >
                 
                  
                  <TimePicker
                        onChange={onStartTimeChange}
                        name='startTime'
                        value={startTime}
                        format="hh:mm a"
                        disableClock={true}
                  />                     
                     

                  <p className='InstructorLable'>To</p>
                  <TimePicker
                        onChange={onEndTimeChange}
                        name='endTime'
                        value={endTime}
                        format="hh:mm a"
                        disableClock={true}
                  />                     
                     
                </div>
              </div>
            </div>
            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Select Center*</p>
                <select
                  className='InstructorName'
                  style={{ width: '15vw' }}
                  name='center'
                  value={center}
                  onChange={(e) => handleCenterChange(e)}
                >
                  <option value={''}>Please select</option>
                  {centersData.map((center) => (
                    <option value={center._id}>{center.centerName}</option>
                  ))}
                </select>
              </div>
              <div>
                <p className='InstructorLable'>Days*</p>
                <Multiselect
                  options={daysData}
                  //  displayValue="days"
                  displayValue='days'
                  name='days'
                  selectedValues={batchDays}
                  onSelect={handleDaysChange}
                  onRemove={handaleDaysDelete}
                />
              </div>
            </div>
            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Style*</p>
                <Multiselect
                  options={styleData}
                  displayValue='courseName'
                  name='style'
                  selectedValues={batchStyles}
                  onSelect={handleStyleChange}
                  onRemove={handaleStyleDelete}
                />
              </div>
              <div style={{ width: '15vw' }}>
                <p className='InstructorLable'>Instructor*</p>
                <Multiselect
                  options={instructorData}
                  displayValue='name'
                  name='instructor'
                  selectedValues={batchInstructors}
                  onSelect={handleInstructorChange}
                  onRemove={handaleInstructorDelete}
                />
              </div>
            </div>
            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Registration Fees*</p>
                <input
                  className='InstructorName'
                  type='text'
                  placeholder='0'
                  style={{ width: '15.6vw' }}
                  name='registrationFees'
                  value={registrationFees}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div>
                <p className='InstructorLable'>Fees*</p>
                <input
                  className='InstructorName'
                  type='text'
                  placeholder='0'
                  style={{ width: '15vw' }}
                  name='fees'
                  value={fees}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Occupancy*</p>
                <input
                  className='InstructorName'
                  type='number'
                  placeholder='0'
                  style={{ width: '15.6vw' }}
                  name='occupancy'
                  value={occupancy}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div>
                <p className='InstructorLable'>Has Demo?*</p>
                <select
                  className='InstructorName'
                  style={{ width: '15vw' }}
                  name='hasDemo'
                  value={hasDemo}
                  onChange={(e) => onInputChange(e)}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>
            </div>
            
            
            
            
            <div className='InstructorTopContent'>
            
             <div>
                <p className='InstructorLable'>Instructor Payment*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='0'
                  name='paymentToInstructor'
                  value={paymentToInstructor}
                  onChange={(e) => onInputChange(e)}
                />
            </div>              
              
              
            </div>
            
            
            
            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Instructor Penalty per Absence*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='0'
                  name='instructorPenalty'
                  value={instructorPenalty}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div>
                <p className='InstructorLable'>Action*</p>
                <select
                  className='InstructorName'
                  style={{ width: '15vw' }}
                  name='isSuspended'
                  value={isSuspended}
                  onChange={(e) => onInputChange(e)}
                >
                  <option value={false}>Active</option>
                  <option value={true}>Suspend</option>
                </select>
              </div>
            </div>
            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Number of Classes*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='Enter number of classes'
                  name='numClasses'
                  value={numClasses}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
              <div>
                <p className='InstructorLable'>Number Freeze Limits*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '15.6vw' }}
                  placeholder='Enter number of freeze limts'
                  name='numFreezeLimit'
                  value={numFreezeLimit}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className='instructorButton'>
              <button className='InstructorCancle' onClick={props.handleClose}>
                Cancel
              </button>
              <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
            </div>
          </form>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Batch Updated
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage.substring(0, 50)}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
}
