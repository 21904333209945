import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Brand from '../components/Brand';
import { RiStore3Line } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { sidebar as navigation } from '../constants/navigation';
import { useTranslator } from '../services/localise';

import logo from '../Assets/Image/dmentors_icon.png';

const SidebarItem = ({ navItem, color, collapsed = false }) => {
  const tt = useTranslator();

  return (
    <NavLink
      to={navItem.path}
      activeClassName={`bg-${color}-200 text-${color}-900 border-l-4 border-${color}-500`}
      className={`${
        collapsed ? 'h-16' : 'h-12'
      } text-black-500 w-full flex items-center hover:bg-gray-200 transition-all duration-300 ease-in-out`}
    >
      <div className={`${collapsed ? 'h-16' : 'h-12'} w-16 flex transition-all duration-300 ease-in-out`}>
        {React.cloneElement(navItem.icon, { size: 20, className: `m-auto` })}
      </div>
      <div className='flex-grow w-0 whitespace-no-wrap overflow-hidden font-medium opacity-75'>
        {tt(navItem.title)}
      </div>
    </NavLink>
  );
};

const SidebarItemTitle = ({ title, collapsed }) => {
  const mini = useMemo(
    () =>
      title
        .split(' ')
        .map((i) => i[0])
        .join('')
        .toUpperCase(),
    [title]
  );
  return (
    <div className='p-2 flex items-center'>
      {!mini && <div className='w-8 mr-auto h-0 border-b' />}
      <div className='text-gray-500 font-medium uppercase mx-auto text-xs whitespace-no-wrap overflow-hidden'>
        <span className={collapsed ? '' : 'md:hidden'}>{mini}</span>
        {!collapsed && <span className='hidden md:inline'>{title}</span>}
      </div>
      {!mini && <div className='w-8 ml-auto h-0 border-b' />}
    </div>
  );
};

const Sidebar = () => {
  const { sidebar, color, rtl } = useSelector((state) => state.ui, shallowEqual);
  const tt = useTranslator();

  return (
    <div
      className={`w-16 ${sidebar.collapsed ? '' : 'md:w-64'} bg-white border-${
        rtl ? 'l' : 'r'
      } transition-all 
            duration-500 ease-in-out overflow-hidden flex flex-col shadow-sm`}
    >
      <div className='h-16 border-b'>
        <Brand image={logo} color={color} name={tt('DMENTORS')} collapsed={sidebar.collapsed} />
      </div>
      <div className='flex-grow overflow-y-auto overflow-x-hidden' style={{ marginTop: '20px' }}>
        {navigation.map((item) =>
          item.isTitle ? (
            <SidebarItemTitle collapsed={sidebar.collapsed} title={item.title} />
          ) : (
            <SidebarItem
              key={item.path}
              depth={1}
              color={color}
              collapsed={sidebar.collapsed}
              navItem={item}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Sidebar;
