import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
// import PaymentModal from "./PaymentModal";
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';

export default function StudentProgress() {	
	
  const [open, setOpen] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [studentProgress, setStudentProgress] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    'Style',
    'Batch',
    'Instructor',
    'Category',
    'Level',
    'Strength',   
    'Flexibility',    
    'Stamina',
    'Gasping Power',
    'Concentration',
    'Memory Power',
    'Musicality',
    'Expression',
    'Choreography Execution',
    'Energy Level',
    'Punctuality',
    'Regularity',
    'Discipline',
    'Reviewed On',      
  ];

  
  
  useEffect(() => {  	

       getStudentProgress();
       
  }, [addSuccess]);
  
  
  const getStudentProgress = async () => {
  	
  	 var currentURL = window.location.href;
  	 var urlParts = currentURL.split('/');  	 

  	 var studentId = urlParts[4];
  	
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/users/students/getProgress?id='+studentId);
      setOnLoading(false);
      console.log(data.studentReviews);
      setStudentProgress(data.studentReviews);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
  };
  
  var tableData = [];
  
  for(var i=0;i<studentProgress.length;i++){
  
      var review = [];     
      
      review.push(studentProgress[i].batch.courses[0].courseName);
      review.push(studentProgress[i].batch.batchName);
      review.push(studentProgress[i].instructor.userId.firstName);
      
      review.push(studentProgress[i].category);
      review.push(studentProgress[i].level);
      review.push(studentProgress[i].strength);
      review.push(studentProgress[i].flexibility);
      review.push(studentProgress[i].stamina);
      review.push(studentProgress[i].graspingPower);
      review.push(studentProgress[i].concentration);
      review.push(studentProgress[i].memoryPower);
      review.push(studentProgress[i].musicality);
      review.push(studentProgress[i].expression);
      review.push(studentProgress[i].choreographyExecution);
      review.push(studentProgress[i].energyLevel);
      review.push(studentProgress[i].punctuality);
      review.push(studentProgress[i].regularity);
      review.push(studentProgress[i].discipline);
      
      review.push(new Date(studentProgress[i].createdAt).toDateString() + '-' +new Date(studentProgress[i].createdAt).toTimeString());      
           
      tableData.push(review);
  }
  

             
       
  
  return (
    <React.Fragment className='studentTable'>

      <div className='AddButtonPosition'>
        <div className='tableHeader'>Progress 
           {studentProgress.length > 0 &&   
              <span>&nbsp;of <span style={{color:'blue'}}>{studentProgress[0].student.userId.firstName}</span></span>
           }           
        </div>
        
      </div>
      
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
      
    </React.Fragment>
  );
}
