import React, { useEffect, useState } from 'react';
import '../Pages.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
	
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [onLoading, setOnLoading] = useState(false);

  //submit function
  const deleteHandler = async (e) => {
  	
    e.preventDefault();
    
    try {
      setOnLoading(true);
      await axios.delete(`/api/enrollments/?id=${props.enrollment}`);
      setOnLoading(false);
      setSuccessSnackbar(true);
      setTimeout(() => {
        props.handleClose();
        props.deleteSuccess();
      }, 1000);
    } catch (error) {
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Delete Enrollment
        </DialogTitle>

        <DialogContent className='coursesModal' style={{}}>
          <div>
            <p>All the data of this enrollment will be removed from the database.</p>
            <p>Are you sure you want to delete this enrollment?</p>
          </div>
          <div className='instructorButton' style={{ marginTop: '2em' }}>
            <button className='InstructorCancle' onClick={props.handleClose}>
              Cancel
            </button>
            <button onClick={deleteHandler} className='InstructorSave'>
              {onLoading ? <CircularProgress /> : 'Delete'}
            </button>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Enrollment Deleted
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
}
