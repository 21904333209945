/* -------------------------------------------------------------------------- */
/*                             REGULAR EXPRESSIONS                            */
/* -------------------------------------------------------------------------- */
export const EMAIL_PATTERN = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const NAME_PATTERN = /^[A-Za-z][A-Za-z\s]+$/;
export const PHONE_PATTERN = /^[0-9]{10}$/

/* -------------------------------------------------------------------------- */
/*                                   LIMITS                                   */
/* -------------------------------------------------------------------------- */
export const MIN_PASSWORD_LENGTH = 2;
export const MAX_PASSWORD_LENGTH = 25;