import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

const Tabs = ({items=[]}) => {

    const {color, rtl} = useSelector(state => state.ui, shallowEqual)

    return (
        <div className="flex items-stretch bg-white w-full h-12 border-b">
            {
                items.map(item => (
                    <NavLink 
                        key={item.path}
                        to={item.path} 
                        exact
                        activeClassName={`text-${color}-500 bg-${color}-100`}
                        className={`flex items-center text-sm text-gray-500 hover:text-${color}-500 transition duration-300 ease-in-out`}>
                        <div className="flex w-12 h-12 items-center justify-center">
                            {React.cloneElement(item.icon, {size: 18})}
                        </div>
                        <div className={`${rtl ? 'pl': 'pr'}-4 text-center w-48 hidden md:block`}>
                            {item.title}
                        </div>
                    </NavLink>
                ))
            }
        </div>
    )
}

export default Tabs
