import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
// import PaymentModal from "./PaymentModal";
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';

export default function StudentCoupons() {	
	
  const [open, setOpen] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [studentCoupons, setStudentCoupons] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    'Style',
    'Enrolled Batch',
    'Coupon Value Used',   
    'Used Date',       
  ];

  
  
  useEffect(() => { 	

       getStudentCoupons();
       
  }, [addSuccess]);
  
  
  const getStudentCoupons = async () => {
  	
  	 var currentURL = window.location.href;
  	 var urlParts = currentURL.split('/');  	 

  	 var studentId = urlParts[4];
  	
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/users/students/getCoupons?id='+studentId);
      setOnLoading(false);
      console.log(data.enrollments);
      setStudentCoupons(data.enrollments);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
  };
  
  var tableData = [];
  
  for(var i=0;i<studentCoupons.length;i++){
  
      var enrollment = [];     
      
      enrollment.push(studentCoupons[i].course.courseName);
      enrollment.push(studentCoupons[i].batch.batchName);     
      
      enrollment.push('₹'+studentCoupons[i].transaction.discountAmount);
      enrollment.push(new Date(studentCoupons[i].transaction.createdAt).toDateString() + ' - ' +new Date(studentCoupons[i].transaction.createdAt).toLocaleTimeString()+' IST');     
      
      tableData.push(enrollment);
  }
  

             
                
                           

             
  
  return (
    <React.Fragment className='studentTable'>

      <div className='AddButtonPosition'>
        <div className='tableHeader'>Coupons used 
        {studentCoupons.length > 0 &&   
           <span>&nbsp;by <span style={{color:'blue'}}>{studentCoupons[0].student.userId.firstName}</span></span>
        }           
           </div>
        
      </div>
      
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
      
    </React.Fragment>
  );
}
