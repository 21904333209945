import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
// import PaymentModal from "./PaymentModal";
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';


export default function StudentTable() {
	
  const [open, setOpen] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          backgroundColor: "#ff0000"
        }
      }
    }
  })

  
  const suspendStudent = async(studentId) => {
  	
  	console.log(studentId);
    
    try {
    	
      setOnLoading(true);
      const { data } = await axios.post('/api/admin/suspendStudent?userId='+studentId);
      setOnLoading(false);
      loadStudents();
      
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
    
        
  };
  
  
  const restoreStudent = async(studentId) => {
    
    try {
    	
      setOnLoading(true);
      const { data } = await axios.post('/api/admin/revokeStudentSuspension?userId='+studentId);
      setOnLoading(false);
      loadStudents();
      
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
    
        
  };
  

   
  
  const history = useHistory();


  const columns = [
    'Student',
    'Style',
    'Batch',
    'Instructors',
    {
          label: "Packages",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
          	
          	console.log(value);
              return (
                  <button className="ViewTableButton" onClick={() => history.push('/student-packages/'+value) }>
                    View
                  </button>
              )
          }
         }
     },
    {
          label: "Coupons",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <button className="ViewTableButton" onClick={() => history.push('/student-coupons/'+value) }>
                    View
                  </button>
              )
          }
         }
     },
     {
          label: "Attendance",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <button className="ViewTableButton" onClick={() => history.push('/student-attendance/'+value) }>
                    View
                  </button>
              )
          }
         }
     },
     {
          label: "Payments",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <button className="ViewTableButton" onClick={() => history.push('/student-payments/'+value) }>
                    View
                  </button>
              )
          }
         }
     },
     {
          label: "Progress",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <button className="ViewTableButton" onClick={() => history.push('/student-progress/'+value) }>
                    View
                  </button>
              )
          }
         }
     },
     'Status',
     {
          label: "Action",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
          	
          	  var valuesArray = value.split('#');
          	  var studentId = valuesArray[0];
          	  var studentStatus = valuesArray[1];
          	  
          	  console.log(studentStatus);
          	  
              return (
              
                <div>
              
                 {(studentStatus == 'Active' || studentStatus=='active') &&
                
                  <button className="EditTableButton" 
                      style={{fontSize:11}}
                      onClick={() => suspendStudent(studentId)}>
                    Suspend
                  </button>
                  
                 }
                 
                 {studentStatus == 'Suspended' &&
                
                  <button className="ViewTableButton" 
                      style={{fontSize:11}}
                      onClick={() => restoreStudent(studentId)}>
                    Restore
                  </button>
                  
                 }
                  
                </div>  
              )
          }
         }
     },
    
  ];

  
  useEffect(() => {
    loadStudents();
  }, [addSuccess]);

  const loadStudents = async () => {
  	
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/users/students/getAll');
      setOnLoading(false);
      console.log(data.students);
      setStudentData(data.students);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
  };

  return (
    <React.Fragment className='studentTable'>

      <div className='AddButtonPosition'>
        <div className='tableHeader'>STUDENTS</div>
        
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '-1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
        


          <MUIDataTable
            data={studentData.map((student) => {
              return [
                student.userId && student.userId.firstName,
                
                student.enrollments.length > 0 &&
                student.enrollments[0].course &&
                student.enrollments[0].course.courseName,
                
                student.enrollments.length > 0 &&
                student.enrollments[0].batch &&
                student.enrollments[0].batch.batchName,
                
                student.enrollments.length > 0 &&
                student.enrollments[0].batch &&
                student.enrollments[0].batch.instructors[0] &&
                student.enrollments[0].batch.instructors[0].userId.firstName,
                
                student._id,
                student._id,
                student._id,
                student._id,
                student._id,
                
                student.userId.status.toUpperCase(),
                
                student.userId._id + '#' + student.userId.status,
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
          


        )}
      </div>
    </React.Fragment>
  );
}
