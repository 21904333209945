import React, { useCallback, useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const LocalisationContext = React.createContext(null);

export const LocalisationProvider = ({ children, langs }) => {
  const locale = useSelector((state) => state.ui.locale, shallowEqual);

  const translator = useCallback(
    (text) => {
      if (locale in langs && text in langs[locale]) {
        return langs[locale][text];
      }
      return text;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  return <LocalisationContext.Provider value={translator}>{children}</LocalisationContext.Provider>;
};

/**
 * Gets the translator function for localising content
 * @return {(text: string) => string}
 */
export function useTranslator() {
  const translator = useContext(LocalisationContext);
  return translator;
}
