import React, { useState, useEffect } from 'react';
import '../Pages.css';
import { FaEdit } from 'react-icons/fa';
import { GrView } from 'react-icons/gr';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReactDOM from 'react-dom';
import MUIDataTable from 'mui-datatables';
import CalendarEditView from './CalendarEditView';
import { CircularProgress } from '@material-ui/core';
import AddBatches from './AddBatches';
import EditBatch from './EditBatch';
import DeleteBatch from './DeleteBatch';
import axios from 'axios';
import { Delete } from '@material-ui/icons';

export default function BatchesTable() {
	
  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [selectedBatch, setSelectedBatch] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [batches, setBatches] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      name: 'ID',
      options: {
        display: false,
      },
    },
    'Center',
    'Batch Name',
    'Styles',
    'Days',
    'Time',
    'Instructor',
    'Students',
    {
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                className='EditTableButton'
                style={{fontSize:11}}
                onClick={() => {
                  setSelectedBatch(tableMeta.rowData[0]);
                  setOpenEdit(true);
                }}
              >
                View | Edit
              </button>
              
              <button
                className='deleteIcon'
                style={{marginTop:5}}
                onClick={() => {
                  setSelectedBatch(tableMeta.rowData[0]);
                  setOpenDelete(true);
                }}
              >
                <Delete />
              </button>
            </>
          );
        },
      },
    },
  ];

  useEffect(() => {
    loadBatches();
  }, [addSuccess]);

  const loadBatches = async () => {
    try {
      setOnLoading(true);
      const { data } = await axios.get('api/batches/getAll');
      setBatches([...data.batches]);
      setOnLoading(false);
    } catch (error) {
      // console.log(error.response.data);
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setOnLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/* <CalendarEditView handleClose={handleClose} open={open}/> */}
      {open && (
        <AddBatches handleClose={handleClose} open={open} addSuccess={() => setAddSuccess(!addSuccess)} />
      )}
      {openEdit && (
        <EditBatch
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          id={selectedBatch}
          editSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      {openDelete && (
        <DeleteBatch
          handleClose={() => setOpenDelete(false)}
          open={openDelete}
          batch={selectedBatch}
          deleteSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      <div className='AddButtonPosition'>
        <div className='tableHeader'>BATCHES</div>
        <button className='AddButton' onClick={handleClickOpen}>
          Add a Batch
        </button>
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={batches.map((batch) => {
              return [
                batch._id,
                batch.center,
                batch.batchName,
                batch.courses,
                batch.batchDays,
                batch.startTime,
                batch.instructors,
                batch.students,
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false,
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
