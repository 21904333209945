import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Snackbar } from '@material-ui/core';
import axios from 'axios';
import { Alert } from 'antd';

export default function LeaveRequestTable() {
	
  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [seletedRequest, setSeletedRequest] = useState('');
  const [addSuccess, setAddSuccess] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [isApproved, setIsApproved] = useState('');
  const [successSnackbar, setSuccessSnackbar] = useState(false);


  const columns = [
    {
      name: 'ID',
      options: {
        display: false,
      },
    },
    'Instructor Name',
    'Batch Name',
    'Applied On',
    'Applied For',
    'Status',
    {
      label: 'Action',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {       	

        	
          return tableMeta.rowData.includes('Applied') ? (
            <>
              <button
                className='ViewTableButton'
                style={{ width: '40%', fontSize:10, marginRight: '.6em' }}
                onClick={(e) => {

                  //setSeletedRequest(tableMeta.rowData[0]);
                  //setIsApproved(true);
                  
                  // Passing with the function because state takes some time to set
                  leaveActionSubmit(tableMeta.rowData[0], true);
                }}
              >
                Approve
              </button>
              
              <button
                className='ViewTableButton'
                style={{ width: '36%', fontSize:10,  backgroundColor: 'red' }}
                onClick={async (e) => {
                	

                  //setSeletedRequest(tableMeta.rowData[0]);
                  
                  // Passing with the function because state takes some time to set
                  //setIsApproved(false);
                  leaveActionSubmit(tableMeta.rowData[0], false);
                }}
              >
                Reject
              </button>
            </>
          ) : (
            <>-</>
          );
        },
      },
    },
  ];

  useEffect(() => {
    loadRequests();
  }, [addSuccess]);
  const loadRequests = async () => {
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/users/instructors/all-leave-request');
      setOnLoading(false);
      setRequestData(data.leaveRequests);
    } catch (error) {
      // console.log(
      //   error.response && error.response.data.message ? error.response.data.message : error.message
      // );
      setOnLoading(false);
    }
  };

  //submit admin action
  const leaveActionSubmit = async (selectedRequest, isApproved) => {
  	

    
    if (selectedRequest) {
    	
    	setOnLoading(true);
    	
      try {
        const { data } = await axios.put(`/api/users/instructors/update-leave-Request?id=${selectedRequest}`, {
          isApproved,
        });
        setSuccessSnackbar(true);
        setTimeout(() => {
          setAddSuccess(!addSuccess);
        }, 2000);
      } catch (error) {}
    }
  };

  return (
    <React.Fragment>
      <div className='AddButtonPosition'>
        <div className='tableHeader'>Leave Requests</div>
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={requestData.map((request) => {
              return [
                request._id,
                request.instructor && request.instructor.userId.firstName,
                request.class && request.class.batch.batchName,
                request.leaveAppliedOn,
                request.class && request.class.date,
                request.attendanceStatus.split(' ')[1],
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Leave Request Updated
            </Alert>
          </Snackbar>
          {/* <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage.substring(0, 50)}
            </Alert>
          </Snackbar> */}
        </>
      </div>
    </React.Fragment>
  );
}
