import React, { useEffect, useMemo, useState } from 'react';
import { FcShop } from 'react-icons/fc';
import { RiAtLine, RiKeyLine } from 'react-icons/ri';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HideLoader, ShowLoader } from '../../redux/actions/ui';
import { userLoginAction } from '../../redux/actions/auth';
import { EMAIL_PATTERN, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../constants/validations';
import { toast } from 'react-toastify';
import Logo from '../../Assets/Image/appLogo.png';
import { CircularProgress } from '@material-ui/core';

const LoginPage = () => {
	
  const dispatch = useDispatch();
  const history = useHistory();

  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [dirty, setDirty] = useState({ email: false, password: false });

  const handleChange = (key, val) => {
    setCredentials((c) => ({ ...c, [key]: val }));
    setDirty((d) => ({ ...d, [key]: true }));
  };

  // if already logged in redirect
  const { user, loading, error } = useSelector((state) => state.auth);
  console.log(user);
  useEffect(() => {
    if (user) {
      history.push('/dashboard');
    }
  }, [user, history]);

  /* -------------------------------------------------------------------------- */
  /*                                 VALIDATION                                 */
  /* -------------------------------------------------------------------------- */

  const errors = useMemo(() => {
    const errs = {};
    const { email, password } = credentials;
    // EMAIL VALIDATION
    if (dirty.email) {
      if (email.trim().length <= 0) {
        errs.email = 'Email is required';
      } else if (!EMAIL_PATTERN.test(email)) {
        errs.email = 'Invalid email address';
      }
    }

    if (dirty.password) {
      if (password.trim().length <= 0) {
        errs.password = 'Password is required';
      } else if (password.trim().length < MIN_PASSWORD_LENGTH) {
        errs.password = `Password should have minimum ${MIN_PASSWORD_LENGTH} characters`;
      } else if (password.trim().length > MAX_PASSWORD_LENGTH) {
        errs.password = `Password should not exceed ${MAX_PASSWORD_LENGTH} characters`;
      }
    }
    return errs;
  }, [dirty, credentials]);
  const valid = useMemo(() => {
    return Object.keys(errors).length <= 6 && Object.values(dirty).some((v) => v);
  }, [errors, dirty]);

  /**
   * Login
   * @param {any} cred - credentials to login
   */
  //   const login = async (cred = null) => {
  //     // LOGIN LOGIC
  //     return {
  //       name: 'John Doe',
  //       email: cred.email,
  //     };
  //   };

  /**
   * Submit the login request
   * @param {Event} e - onsubmit event
   */

  const handleSubmit = async (e) => {
    e && e.preventDefault();
    try {
      if (valid) {
        dispatch(userLoginAction({ email: credentials.email, password: credentials.password }));
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      dispatch(HideLoader());
    }
  };

  return (
    <div 
         className='flex items-center p-3 h-screen bg-gradient-to-tr from-primary-500 to-secondary-500'
         style={{backgroundColor:'white', borderRadius:10}}
    >
    
      <div className='m-auto p-3 md:p-10 bg-white rounded-xl md:flex w-full md:w-1/2' 
           style={{backgroundColor:'black'}}>
        <div className='p-3 md:p-5 md:w-1/2 w-full flex'>
          <div className='m-auto w-58 h-18 bg-gray-200 flex items-center justify-center '>
            {/* <FcShop size={80}/> */}
            <img style={{ background: '#150808' }} src={Logo} alt='DMENTORS' />
          </div>
        </div>
        
        <div className='p-3 md:p-5 md:w-1/2 w-full flex flex-col items-center'>
          <h3 className='font-bold text-gray-800 md:text-xl text-lg text-center' 
              style={{color:'white'}}>Admin Login</h3>
          <form onSubmit={handleSubmit} className='mt-4 md:mt-8 w-full' >
            <div className='w-full input-grp'>
              <RiAtLine size={16} />
              <input
                name='email'
                value={credentials.email}
                onChange={(e) => handleChange('email', e.target.value.trim())}
                type='text'
                style={{padding:10, width:'100%', borderRadius:5}}
                placeholder='Email'
              />
            </div>
            
            {errors.email && <div className='mt-1 text-red-500 text-xs text-right'>{errors.email}</div>}
            <div className='mt-4 w-full input-grp'>
              <RiKeyLine size={16} />
              <input
                name='password'
                value={credentials.password}
                onChange={(e) => handleChange('password', e.target.value)}
                type='password'
                style={{padding:10, width:'100%', borderRadius:5}}
                placeholder='Password'
              />
            </div>
            {errors.password && <div className='mt-1 text-red-500 text-xs text-right'>{errors.password}</div>}

            <button
              disabled={!valid}
              style={{backgroundColor:'white', color:'black', display:'block', paddingTop:10, borderRadius:5, paddingBottom:10}}
              className={`mt-6 w-full button secondary text-sm ${valid ? '' : 'opacity-25'}`}
            >
              {loading ? (
                <CircularProgress size={22} style={{ marginBottom: '-.4em', margin: '.1em 0' }} />
              ) : (
                'Login'
              )}
            </button>
            {errors && <div className='mt-1 text-red-500 text-xs text-right'>{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
