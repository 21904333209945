import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

// layouts
import DashboardLayout from './layouts/Dashboard';

// pages
import LoginPage from './pages/login';
import IndexPage from './pages/dashboard';
import Center from './pages/Center/CenterTable';
import Courses from './pages/Courses/CoursesTable';
import Instructors from './pages/instructor/instructortable';
import Batches from './pages/Batches/BatchesTable';
import Student from './pages/Student/StudentTable';
import StudentPackages from './pages/Student/studentPackages';
import StudentCoupons from './pages/Student/studentCoupons';
import StudentAttendance from './pages/Student/studentAttendance';
import StudentPayments from './pages/Student/studentPayments';
import StudentProgress from './pages/Student/studentProgress';

import Enrollments from './pages/Enrollments/enrollmentsTable';

import InstructorAttendance from './pages/instructor/instructorAttendance';
import InstructorPayments from './pages/instructor/instructorPayments';

import DemoRequests from './pages/Student/demoRequests';
import AppBanners from './pages/banners/appBanners';

import Coupons from './pages/Coupons/CouponsTable';
import Payments from './pages/Payments/index';
import LeaveRequestTable from './pages/leaveRequest/leaveRequestTable';
import GalleryVideosTable from './pages/Gallery/GalleryVideosTable';
import axios from 'axios';

const AuthRoute = ({ path, component: Component, render, ...rest }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!user)
          return (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        return Component ? <Component /> : render(props);
      }}
    />
  );
};

const Routes = () => {
  const { user } = useSelector((state) => state.auth);

  (function () {
    const token = user ? user.token : '';
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      axios.defaults.headers.common['Authorization'] = null;
    }
  })();

  return (
    <Switch>
      <Route path='/login'>
        <LoginPage />
      </Route>
      {/* <Route path='/'>
        <LoginPage />
      </Route> */}
      <Switch>
        <DashboardLayout>
          <AuthRoute path='/dashboard' component={IndexPage} />

          <AuthRoute path='/center' component={Center} />

          <AuthRoute path='/courses-style' component={Courses} />

          <AuthRoute path='/instructor' component={Instructors} />
          <AuthRoute path='/instructor-attendance' component={InstructorAttendance} />
          <AuthRoute path='/instructor-payments' component={InstructorPayments} />

          <AuthRoute path='/batches' component={Batches} />

          <AuthRoute path='/students' component={Student} />
          
          <AuthRoute path='/enrollments' component={Enrollments} />
          
          <AuthRoute path='/student-packages' component={StudentPackages} />
          <AuthRoute path='/student-coupons' component={StudentCoupons} />
          <AuthRoute path='/student-attendance' component={StudentAttendance} />
          <AuthRoute path='/student-payments' component={StudentPayments} />
          <AuthRoute path='/student-progress' component={StudentProgress} />


          <AuthRoute path='/coupons' component={Coupons} />

          <AuthRoute path='/gallery-videos' component={GalleryVideosTable} />

          <AuthRoute path='/leave-requests' component={LeaveRequestTable} />

          <AuthRoute path='/payments' component={Payments} />
          
          <AuthRoute path='/demo-requests' component={DemoRequests} />
          
          <AuthRoute path='/app-banners' component={AppBanners} />

          <AuthRoute path='/' exact component={IndexPage} />
        </DashboardLayout>
      </Switch>
    </Switch>
  );
};

export default Routes;
