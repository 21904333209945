import React from 'react'
import { useTransition, animated } from 'react-spring'

const Backdrop = ({show=true, children}) => {

    const overlayTransitions = useTransition(show, null, {
        from: { opacity: 0},
        enter: { opacity: 1},
        leave: { opacity: 0}
    })

    return (
        <>
        {
            overlayTransitions.map(({item, props, key}) => (
                item &&
                <animated.div 
                    key={key}
                    style={props}
                    className="w-screen h-screen flex fixed top-0 left-0 bg-black bg-opacity-25 z-50">
                    {children}
                </animated.div>
            ))
        }
        </>
    )
}

export default Backdrop
