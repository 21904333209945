import React from 'react';
import "../Pages.css";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className="AddEditInstructor">View Courses / Style</DialogTitle>
        <DialogContent>
          <div  className="InstructorTopContent">
            <div>
              <p className="InstructorLable">Center</p>
              <input className="InstructorName" type="text" placeholder="Center 01" />
            </div>
            <div>
              <p className="InstructorLable">No. Daily Batches</p>
              <input className="InstructorName" type="number" placeholder="No. Daily Batches" />
            </div>
          </div>
          <div  className="InstructorTopContent">
            <div>
              <p className="InstructorLable">No. Instructors</p>
              <input className="InstructorName" type="number" placeholder="No. Instructors" />
            </div>
            <div>
              <p className="InstructorLable">No. Students</p>
              <input className="InstructorName" type="number" placeholder="No. Students" />
            </div>
          </div>
          <div style={{paddingLeft: '3%'}}>
              <p className="InstructorLable">Address</p>
              <textarea className="AddCoursesTextarea" rows="4" type="number" placeholder="Address" />
          </div>
          
          
          <div className="instructorButton">
            <button className="InstructorCancle" onClick={props.handleClose}>Cancle</button>
            <button className="InstructorSave" onClick={props.handleClose}>Save</button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}