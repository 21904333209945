import React, { useState } from 'react';
import '../Pages.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [center, setCenter] = useState({
    centerName: '',
    address: '',
  });
  const [onLoading, setOnLoading] = useState(false);

  const { centerName, address } = center;
  const onInputChange = (e) => {
    setCenter({ ...center, [e.target.name]: e.target.value });
  };

  const addCentersHandler = async (e) => {
    e.preventDefault();

    if (center.centerName && center.address) {
      try {
        setOnLoading(true);
        const { data } = await axios.post('api/centers/add', { centerName, address });

        setOnLoading(false);
        setSuccessSnackbar(true);
        setTimeout(() => {
          props.handleClose();
          props.addSuccess();
        }, 1000);
      } catch (error) {
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please fill all require fields');
      setErrorSnackbar(true);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Add Center
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => addCentersHandler(e)}>
            <div>
              <p className='InstructorLable'>Center Name*</p>
              <input
                style={{ width: '95%' }}
                className='InstructorName'
                type='text'
                placeholder='Center name'
                name='centerName'
                value={centerName}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div>
              <p className='InstructorLable'>Address*</p>
              <textarea
                type='text'
                className='InstructorName'
                placeholder='Address'
                style={{
                  width: '95%',
                  paddingTop: '5px',
                }}
                rows='2'
                name='address'
                value={address}
                onChange={(e) => onInputChange(e)}
              />
            </div>

            <div className='instructorButton'>
              <button className='InstructorCancle' onClick={props.handleClose}>
                Cancel
              </button>
              <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
            </div>
          </form>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Center Added
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
}
