export default {
    "Actions": "Actions",
    "Admin": "Admin",
    "Cancel": "Cancel",
    "Date": "Date",
    "Name": "Name",
    "Shutdown": "Shutdown",
    "Shutdown shop": "Shutdown shop",
    "This action cannot be undone": "This action cannot be undone",
    "Username": "Username",
    "Verify": "Verify",
    "Verify shop": "Verify shop",
    "Yes, Shutdown": "Yes, Shutdown",
    "Yes, Verify": "Yes, Verify"
}