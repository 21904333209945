import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTransition, animated } from 'react-spring'
import Backdrop from '../components/Backdrop'
import { HideConfirm } from '../redux/actions/ui'

const ConfirmDialog = () => {

    const dispatch = useDispatch()
    const {confirm, color} = useSelector(state => state.ui, shallowEqual)

    const cardTransitions = useTransition(confirm, null, {
        from: { transform: 'scale(0.3, 0.3)'},
        enter: { transform: 'scale(1, 1)'},
        leave: { transform: 'scale(0.3, 0.3)'}
    })

    const handleCancel = () => {
        dispatch(HideConfirm())
        confirm.onCancel && confirm.onCancel()
    }

    const handleConfirm = () => {
        dispatch(HideConfirm())
        confirm.onConfirm && confirm.onConfirm()
    }

    return (
        <Backdrop show={!!confirm}>
            <div className="m-auto lg:w-1/3">
                {
                    cardTransitions.map(({item, props, key}) => (
                        item &&
                        <animated.div key={key} style={props} className="card bg-white w-full">
                            <h3 className="text-gray-700 text-lg font-semibold text-center">
                                {item.title}
                            </h3>
                            <div className="mt-1 text-gray-600 text-center">
                                {item.text}
                            </div>
                            <div className="mt-4 flex items-center justify-center space-x-2">
                                <button 
                                    onClick={handleCancel}
                                    className="button min-w-32 bg-gray-100 text-gray-600 hover:bg-gray-200">
                                    {item.cancelText}
                                </button>
                                <button 
                                    onClick={handleConfirm}
                                    className={`button min-w-32 bg-${color}-500 text-white hover:bg-${color}-700`}>
                                    {item.okText}
                                </button>
                            </div>
                        </animated.div>
                    ))
                }
            </div>
        </Backdrop>
    )
}

export default ConfirmDialog
