import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
// import PaymentModal from "./PaymentModal";
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';

export default function StudentPackages() {	
	
  const [open, setOpen] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [studentPackages, setStudentPackages] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    'Style',
    'Instructors',
    'Classes Done',   
    'Next Class',  
    'Next Fee Due Date',   
  ];

  
  
  useEffect(() => {
  	
  	   
  	

       getStudentPackages();
       
  }, [addSuccess]);
  
  
  const getStudentPackages = async () => {
  	
  	 var currentURL = window.location.href;
  	 var urlParts = currentURL.split('/');  	 

  	 var studentId = urlParts[4];
  	
    try {
      setOnLoading(true);
      const { data } = await axios.get('/api/users/students/getPackages?id='+studentId);
      setOnLoading(false);
      console.log(data.enrollments);
      setStudentPackages(data.enrollments);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );

    }
  };
  
  var tableData = [];
  
  for(var i=0;i<studentPackages.length;i++){
  
      var enrollment = [];     
      
      enrollment.push(studentPackages[i].course.courseName);
      enrollment.push(studentPackages[i].batch.instructors[0].userId.firstName);      
      
      var classesDone = 0;
      var totalClasses = 0;
      totalClasses = studentPackages[i].classes.length;      
     
      
      for(var j=0;j<studentPackages[i].classes.length;j++){
         if(studentPackages[i].classes[j].attendance.length>0) {
             if(studentPackages[i].classes[j].attendance[0].attendanceStatus == 'Present') {
                classesDone = classesDone + 1;             
             }        
         }     
      }
      
      let nextClass;      
      
      var nowDate = new Date();
      
      for(var j=0;j<studentPackages[i].classes.length;j++){
      	
             if(new Date(studentPackages[i].classes[j].date + ' '+studentPackages[i].classes[j].startTime) > nowDate) {
                nextClass = studentPackages[i].classes[j];   
                break;        
             }             
      }      
      
      enrollment.push(classesDone+'/'+totalClasses);      
      enrollment.push(nextClass.date);      
      tableData.push(enrollment);
  }
  

             
                
                           

             
  
  return (
    <React.Fragment className='studentTable'>

      <div className='AddButtonPosition'>
        <div className='tableHeader'>Packages 
        {studentPackages.length > 0 &&   
           <span>&nbsp;of <span style={{color:'blue'}}>{studentPackages[0].student.userId.firstName}</span></span>
        }           
           </div>
        
      </div>
      
      <div style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1.6em' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
      
    </React.Fragment>
  );
}
