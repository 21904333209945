import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = {
  auth: {
    user: userInfoFromStorage,
  },
  ui: {
    loading: false,
    sidebar: {
      collapsed: false,
    },
    locale: 'en',
    rtl: false,
    confirm: null,
    color: 'primary',
  },
};

const middleware = [thunk];

export default createStore(rootReducer, initialState, applyMiddleware(...middleware));
