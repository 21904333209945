import React from 'react';
import { RiMeteorLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

const Brand = ({ image, icon, name, color = 'primary', collapsed = false }) => {
  const history = useHistory();
  return (
    <div className='flex items-center' style={{ width: '100%' }}>
      <div
        onClick={() => history.push('/dashboard')}
        className='w-16 h-16 flex items-center justify-center '
        style={{ width: '100%', cursor: 'pointer', background: '#000000', overflow: 'hidden' }}
      >
        {image ? (
          <img
            src={image}
            className='w-12 h-12 object-cover'
            alt='logo'
            style={{ width: '45%', transform: 'scale(1.4)', background: '#000000' }}
          />
        ) : icon ? (
          React.cloneElement(icon, { size: 24, className: `text-${color}-500` })
        ) : (
          <RiMeteorLine size={24} className='text-orange-500' />
        )}
      </div>
      {/* {name && !collapsed && (
        <div className='overflow-hidden flex-grow w-0 whitespace-no-wrap hidden md:block'>
        <div className='font-bold text-lg text-gray-800'>{name}</div>
        </div>
      )} */}
    </div>
  );
};

export default Brand;
