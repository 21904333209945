import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RiLogoutBoxLine, RiMenuLine } from 'react-icons/ri';
import { ToggleSidebar } from '../redux/actions/ui';
import Avatar from '../components/Avatar';
// import { NavLink } from 'react-router-dom';
import { userLogout } from '../redux/actions/auth';
import ReactTooltip from 'react-tooltip';
import { useTranslator } from '../services/localise';
import { useHistory } from 'react-router';

const Navbar = () => {
  const { user } = useSelector((state) => state.auth);
  const { color, rtl } = useSelector((state) => state.ui, shallowEqual);
  const dispatch = useDispatch();

  const tt = useTranslator();
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, [user, history]);

  const logout = async () => {
    dispatch(userLogout());
  };

  return (
    <div className='h-16 flex items-center bg-white border-b shadow-sm'>
      {user && (
        <button
          onClick={() => dispatch(ToggleSidebar())}
          className={`h-16 w-16 flex text-gray-700 hover:text-${color}-500 transition 
                        duration-500 ease-in-out md:block hidden`}
        >
          <RiMenuLine className='m-auto' size={24} />
        </button>
      )}
      <div className={`flex items-center ${rtl ? 'mr' : 'ml'}-auto`}>
        {/* {user && <Avatar name={'A'} color='primary' />} */}
        <button
          data-tip
          data-for='logout'
          onClick={logout}
          className={`border-${rtl ? 'r' : 'l'} ${
            rtl ? 'mr' : 'ml'
          }-4 h-16 w-16 flex items-center text-gray-700 justify-center hover:text-${color}-500`}
        >
          <RiLogoutBoxLine size={20} />
          <ReactTooltip id='logout' effect='solid'>
            {tt('Logout')}
          </ReactTooltip>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
