import React from 'react';
import { RiCoupon2Fill, RiDashboardFill } from 'react-icons/ri';
import {
  FaChalkboardTeacher,
  FaUserGraduate,
  FaMoneyCheckAlt,
  FaPhotoVideo,
  FaEnvelopeOpenText,
} from 'react-icons/fa';
import { SiGoogleclassroom } from 'react-icons/si';
import StyleIcon from '@material-ui/icons/Style';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';

/**
 * @typedef {Object} NavItem
 * @property {string} title - The title displayed to user
 * @property {any} icon - The react element corresponding to the icon
 * @property {string} path - The link for the nav item
 */

/**
 * @type {{[key:string]:NavItem[]}}
 */
export const tabs = {
  
};

/** @type {NavItem[]} */
export const sidebar = [
  
  {
    title: 'Dashboard',
    icon: <RiDashboardFill />,
    path: '/dashboard',
  },
  {
    title: 'Centers',
    icon: <CenterFocusStrongIcon />,
    path: '/center',
  },
  {
    title: 'Courses/Styles',
    icon: <StyleIcon />,
    path: '/courses-style',
  },
  {
    title: 'Instructors',
    icon: <FaChalkboardTeacher />,
    path: '/instructor',
  },
  {
    title: 'Batches',
    icon: <SiGoogleclassroom />,
    path: '/batches',
  },
  {
    title: 'Students',
    icon: <FaUserGraduate />,
    path: '/students',
  },
  {
    title: 'Coupons',
    icon: <RiCoupon2Fill />,
    path: '/coupons',
  },
  {
    title: 'Gallery Videos',
    icon: <FaPhotoVideo />,
    path: '/gallery-videos',
  },
  {
    title: 'Leave Requests',
    icon: <FaEnvelopeOpenText />,
    path: '/leave-requests',
  },
  {
    title: 'Payments',
    icon: <FaMoneyCheckAlt />,
    path: '/payments',
  },
  {
    title: 'Demo Requests',
    icon: <FaEnvelopeOpenText />,
    path: '/demo-requests',
  },
  {
    title: 'Enrollments',
    icon: <FaPhotoVideo />,
    path: '/enrollments',
  },
  {
    title: 'App Banners',
    icon: <FaPhotoVideo />,
    path: '/app-banners',
  },
];
