import { 
    SHOW_LOADER, HIDE_LOADER, EXPAND_SIDEBAR, COLLAPSE_SIDEBAR, TOGGLE_SIDEBAR, SET_COLOR, 
    SHOW_CONFIRM, HIDE_CONFIRM 
} from '../types';

const initialState = {
    loading: false,
    sidebar: {
        collapsed: false
    },
    locale: 'en',
    rtl: false,
    confirm: null,
    color: 'primary'
};

export default function (state = initialState, action) {
    switch(action.type) {
        case SHOW_LOADER:
            return {
                ...state,
                loading: true
            }
        case HIDE_LOADER:
            return {
                ...state,
                loading: false
            }
        case EXPAND_SIDEBAR:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    collapsed: false
                }
            }
        case COLLAPSE_SIDEBAR:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    collapsed: true
                }
            }
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    collapsed: !state.sidebar.collapsed
                }
            }
        case SET_COLOR:
            return {
                ...state,
                color: action.payload
            }
        case SHOW_CONFIRM:
            return {
                ...state,
                confirm: action.payload
            }
        case HIDE_CONFIRM:
            return {
                ...state,
                confirm: null
            }
        default:
            return state
    }
};