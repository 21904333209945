import React, { useEffect, useState } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
import { CircularProgress } from '@material-ui/core';
import AddGalleryVideo from './AddGalleryVideo';
import DeleteGalleryVideos from './DeleteGalleryVideos';
import EditGalleryVideo from './EditGalleryVideo';
import axios from 'axios';

export default function GalleryVideosTable() {
  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  const [open, setOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [addSuccess, setAddSuccess] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      name: 'ID',
      options: {
        display: false,
      },
    },
    'Video Name',
    'Course',
    'Instructor',
    'Viedo URL',
    {
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                style={{ width: '50%', padding: '0 .3em 0 .3em' }}
                className='ViewTableButton'
                onClick={() => {
                  setSelectedVideo(tableMeta.rowData[0]);
                  setOpenEdit(true);
                }}
              >
                View | Edit
              </button>
              <button
                className='ViewTableButton'
                style={{ backgroundColor: 'red', marginLeft: '.4em' }}
                onClick={() => {
                  setSelectedVideo(tableMeta.rowData[0]);
                  setOpenDelete(true);
                }}
              >
                Delete
              </button>
            </>
          );
        },
      },
    },
  ];

  useEffect(() => {
    loadGalleryVideos();
  }, [addSuccess]);

  const loadGalleryVideos = async () => {
    try {
      console.log('coupon datasss');
      setOnLoading(true);
      const { data } = await axios.get('api/galleryvideos/getAll');
      setOnLoading(false);
      setVideoData(data.galleryVideos);
      console.log(videoData);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      // console.log(error.data);
      setOnLoading(false);
    }
  };
  return (
    <React.Fragment>
      {open && (
        <AddGalleryVideo
          handleClose={handleClose}
          open={open}
          addSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      {openEdit && (
        <EditGalleryVideo
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          id={selectedVideo}
          editSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      {openDelete && (
        <DeleteGalleryVideos
          handleClose={() => setOpenDelete(false)}
          open={openDelete}
          video={selectedVideo}
          deleteSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      <div className='AddButtonPosition'>
        <div className='tableHeader'>Gallery Videos</div>
        <button 
                className='AddButton' 
                style={{width:'20vw'}}
                onClick={handleClickOpen}>
          Add Gallery Video
        </button>
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={videoData.map((video) => {
              return [
                video._id,

                video.videoTitle.length > 32 ? `${video.videoTitle.substring(0, 30)}...` : video.videoTitle,
                video.course && video.course.courseName,
                video.instructor ? video.instructor.userId.firstName : 'By Admin',
                video.videoURL,
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
