import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../includes/Navbar';
import Sidebar from '../includes/Sidebar';
import Scrollbars from '../components/Scrollbars';
import Tabs from '../components/Tabs';
import { tabs as navigation } from '../constants/navigation';
import { shallowEqual, useSelector } from 'react-redux';

const Dashboard = ({ children }) => {
  const location = useLocation();

  // get tabs for current route
  const tabs = useMemo(() => {
    for (let key in navigation) {
      if (location.pathname.match(key)) return navigation[key];
    }
  }, [location]);

  return (
    <div className={`h-screen w-screen flex bg-gray-100  ''}`}>
      <Sidebar />
      <div className='flex-grow flex flex-col w-0'>
        <Navbar />
        {tabs && <Tabs items={tabs} />}
        <Scrollbars className='flex-grow h-full'>{children}</Scrollbars>
      </div>
    </div>
  );
};

export default Dashboard;
