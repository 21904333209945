import React, { useEffect, useState } from 'react';
import '../Pages.css';
import { Multiselect } from 'multiselect-react-dropdown';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Tooltip } from '@material-ui/core';
import TimePicker from 'react-time-picker';


let daysData = [
  { days: 'Monday' },
  { days: 'Tuesday' },
  { days: 'Wednesday' },
  { days: 'Thursday' },
  { days: 'Friday' },
  { days: 'Saturday' },
  { days: 'Sunday' },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AddEnrollment(props) {
	
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [studentsData, setStudentsData] = useState([]);
  
  const [selectedStudent, setSelectedStudent] = useState(null);  
  
  const [addEnrollment, setAddEnrollment] = useState({
    student: '',
    batch: '',    
  });
  
  const [onLoading, setOnLoading] = useState(false);

  const {    
    student,
    batch,    
  } = addEnrollment;
  
  const onInputChange = (e) => {
  	
    setAddEnrollment({ ...addEnrollment, [e.target.name]: e.target.value });
    
    
    
    
    if(e.target.name == 'student'){
    	
    	  for(var i=0;i<studentsData.length;i++){
    	  	
    	  	 if(e.target.value == studentsData[i]._id){
    	  	 	
             setSelectedStudent(studentsData[i]); 
            
             if(studentsData[i].batches.length>0){
                setAddEnrollment({student:e.target.value, batch:studentsData[i].batches[0]._id}); 
             }
         }   
       }
     } 
     
     
  };

  

  useEffect(() => {
    loadStudentsAndBatches();
  }, []); 

  

  //get all students and batches
  const loadStudentsAndBatches = async () => {
  	
    try {
      setOnLoading(true);
      
      const { data } = await axios.get('/api/admin/getStudentsAndBatches');
      
      setStudentsData(data);  
        
      if(data.length>0){ 
         setSelectedStudent(data[0]);
         
         setAddEnrollment({student:data[0]._id, batch:(data[0].batches.length>0 ? data[0].batches[0]._id : '')}); 
      }

      setOnLoading(false);
    } catch (error) {
    	
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };
  
 
  	
  	

  //submit function
  const addEnrollmentHandler = async (e) => {
  	
    e.preventDefault();
    
    const enrollmentData = {      
      student,
      batch,      
    };
    
    console.log(enrollmentData.student);
    console.log(enrollmentData.batch);
    
    
    if (
      enrollmentData.student &&
      enrollmentData.batch       
    ) {
      try {
      	
       
        setOnLoading(true);
        
        const { data } = await axios.post(`api/admin/addEnrollment`, enrollmentData);
        
        setTimeout(() => {
          props.handleClose();
          props.addSuccess();
        }, 2000);
        
        setOnLoading(false);
        setSuccessSnackbar(true);
        console.log('Enrollment Data', data);
        
      } catch (error) {
        
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please fill all required fields');
      setErrorSnackbar(true);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Adding an Enrollment
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => addEnrollmentHandler(e)}>           
            
            
            <div className='InstructorTopContent'>              
              
               <div>
              
                <p className='InstructorLable'>Select Student*</p>
                <select
                  className='InstructorName'
                  style={{ width: '15vw' }}
                  name='student'
                  value={student}
                  onChange={(e) => onInputChange(e)}
                >
                
               {studentsData.map((student, index) => (   
                  <option value={student._id}>{student.userId.firstName} {student.userId.lastName}</option>                  
                ))}   
                  
                </select>
                
              </div>

              


              <div>
              
                <p className='InstructorLable'>Select Batch*</p>
                {selectedStudent && selectedStudent.batches.length == 0 &&
                  <p style={{color:'red'}}>No enrollable batches</p>
                }
                <select
                  className='InstructorName'
                  style={{ width: '15vw' }}
                  name='batch'
                  value={batch}
                  onChange={(e) => onInputChange(e)}
                >
                
                {selectedStudent && selectedStudent.batches.map((batch, index) => (   
                  <option value={batch._id}>{batch.batchName}</option>                  
                ))}   

                  
                </select>
                
              </div>
             
              
            </div>
            
           
            
            
           
            
            
            <div className='instructorButton'>
              <button className='InstructorCancle' onClick={props.handleClose}>
                Cancel
              </button>
              <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
            </div>
          </form>
          
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Enrollment Added
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage.substring(0, 50)}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
}
