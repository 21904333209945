import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { useDispatch } from 'react-redux';
import { SetUser } from './redux/actions/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmDialog from './includes/Dialog';

function App() {
	
  const dispatch = useDispatch();  

  return (
    <>
      <Router>
        <Routes />
      </Router>
      <ConfirmDialog />
      <ToastContainer hideProgressBar={true} />
    </>
  );
}

export default App;
