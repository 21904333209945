import React, { useState, useEffect } from 'react';
import '../Pages.css';
import MUIDataTable from 'mui-datatables';
import { CircularProgress } from '@material-ui/core';
import EditCenter from './EditCenter';
import AddCenter from './AddCenter';
import DeleteCenter from './DeleteCenter';
import axios from 'axios';
import { Delete } from '@material-ui/icons';

export default function CenterTable(props) {
  const [responsive, setResponsive] = useState('vertical');
  const [tableBodyHeight, setTableBodyHeight] = useState('400px');
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState('');
  // const [openView, setOpenView] = React.useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [seletedCenter, setSeletedCenter] = useState({});
  const [center, setCenter] = useState([]);
  const [addSuccess, setAddSuccess] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };  

  const columns = [
    {
      name: 'ID',
      options: {
        display: false,
      },
    },
    'Center',
    'Address',
    'No. of Daily Batches',
    'No. of Instructors',
    'No. of Student',

    {
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <button
                className='EditTableButton'
                style={{fontSize:11}}
                onClick={() => {
                  setSeletedCenter(tableMeta.rowData);
                  handleClickOpenEdit();
                }}
              >
                View | Edit
              </button>
              <button
                className='deleteIcon'
                onClick={() => {
                  setSeletedCenter(tableMeta.rowData);
                  setOpenDelete(true);
                }}
              >
                <Delete />
              </button>
            </>
          );
        },
      },
    },
  ];

  useEffect(() => {
    loadCenters();
  }, [addSuccess]);

  const loadCenters = async () => {
    try {
      setOnLoading(true);
      const { data } = await axios.get('api/centers/getAll');
      // console.log(data);
      setOnLoading(false);
      setCenter(data.centers);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setOnLoading(false);
    }
  };

  return (
    <React.Fragment>
      {open && (
        <AddCenter handleClose={handleClose} open={open} addSuccess={() => setAddSuccess(!addSuccess)} />
      )}
      {/* <ViewCenter handleClose={handleCloseView} open={openView} /> */}
      {openEdit && (
        <EditCenter
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          center={seletedCenter}
          editSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      {openDelete && (
        <DeleteCenter
          handleClose={() => setOpenDelete(false)}
          open={openDelete}
          center={seletedCenter}
          deleteSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      <div className='AddButtonPosition'>
        <div className='tableHeader'>CENTER</div>
        <button className='AddButton' onClick={handleClickOpen}>
          Add Center
        </button>
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={center.map((centerData) => {
              return [
                centerData._id,
                centerData.centerName,
                centerData.address,
                centerData.batches.length,
                centerData.instructors.length,
                centerData.students.length,
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false, // <===== will turn off checkboxes in rows
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
