import React, { useState } from 'react';
import '../Pages.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AddCoupon(props) {
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [couponAmount, setCouponAmount] = useState('');
  const [onLoading, setOnLoading] = useState(false);

  //submit function
  const addInstructorHandler = async (e) => {
    e.preventDefault();

    if (couponAmount) {
      try {
        setOnLoading(true);
        const { data } = await axios.post('api/coupons/add', { couponAmount });

        setTimeout(() => {
          props.handleClose();
          props.addSuccess();
          props.selectedCoupon(data.coupon.couponCode);
        }, 2000);

        setOnLoading(false);
        setSuccessSnackbar(true);
        console.log('Batch Data', data);
      } catch (error) {
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please enter the coupon amount');
      setErrorSnackbar(true);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Add Coupon
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => addInstructorHandler(e)}>
            <div className='InstructorTopContent'>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <p className='InstructorLable' style={{ fontSize: '1.2em' }}>
                  Amount:
                </p>
                <input
                  className='InstructorName'
                  type='number'
                  placeholder='Amount'
                  name='name'
                  style={{
                    padding: '.6em .6em',
                    fontSize: '1.2em',
                    fontWeight: '500',
                    width: '40%',
                    height: '2.2em',
                    marginLeft: '.3em',
                  }}
                  value={couponAmount}
                  onChange={(e) => setCouponAmount(e.target.value)}
                />
              </div>
            </div>

            <div className='instructorButton'>
              <button className='InstructorCancle' onClick={props.handleClose}>
                Cancel
              </button>
              <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
            </div>
          </form>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Coupon Added
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage.substring(0, 50)}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
}
