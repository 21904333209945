import { SHOW_LOADER, HIDE_LOADER, EXPAND_SIDEBAR, COLLAPSE_SIDEBAR, TOGGLE_SIDEBAR, SET_COLOR, SHOW_CONFIRM, HIDE_CONFIRM } from '../types';

export const ShowLoader = () => ({
    type: SHOW_LOADER
})

export const HideLoader = () => ({
    type: HIDE_LOADER
})

export const ExpandSidebar = () => ({
    type: EXPAND_SIDEBAR
})

export const CollapseSidebar = () => ({
    type: COLLAPSE_SIDEBAR
})

export const ToggleSidebar = () => ({
    type: TOGGLE_SIDEBAR
})

export const SetColor = (color) => ({
    type: SET_COLOR,
    payload: color
})

export const ShowConfirm = ({
    title='Are you sure?', 
    text='This action cannot be undone',
    cancelText='Cancel',
    okText='Ok',
    onCancel=null,
    onConfirm=null
}) => ({
    type: SHOW_CONFIRM,
    payload: {
        title,
        text,
        cancelText,
        okText,
        onCancel,
        onConfirm
    }
})

export const HideConfirm = () => ({
    type: HIDE_CONFIRM,
})