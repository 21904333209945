import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import './styles/index.css';
import { LocalisationProvider } from './services/localise';
import langs from './lang';
import axios from 'axios';

//axios.defaults.baseURL = 'https://app-backend.dmentors.in/';
axios.defaults.baseURL = 'https://d-mentors-backend-keadx.ondigitalocean.app/';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalisationProvider langs={langs}>
        <App />
      </LocalisationProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
