import React, { useEffect, useState } from 'react';
import '../Pages.css';
import { Multiselect } from 'multiselect-react-dropdown';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Cancel, Delete } from '@material-ui/icons';
import { nanoid } from 'nanoid';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AddInstructor(props) {
	
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectStyle, setSelectStyle] = useState([]);
  const [selectCenter, setSelectCenter] = useState([]);
  const [selectBatch, setSelectBatch] = useState([]);
  const [styleData, setStyleData] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [batchData, setbatchData] = useState([]);
  const [imageInput, setImageInput] = useState('');
  const [imageUploaded, setImageUploaded] = useState('');
  
  const [addInstructor, setAddInstructor] = useState({
    name: '',
    email: '',
    shortDescription:'',
    longDescription:'',
    phone: '',
    address: '',
    password: '',
  });
  
  const [onLoading, setOnLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, shortDescription, longDescription, phone, address, password } = addInstructor;
  const onInputChange = (e) => {
    setAddInstructor({ ...addInstructor, [e.target.name]: e.target.value });
  };

  const handleSelectStyle = (list, item) => {
    setSelectStyle((selectStyle) => [...selectStyle, item]);
  };
  const handaleSelectStyleDelete = (list, item) => {
    const updateSelectStyle = selectStyle.filter((i) => i._id !== item._id);
    setSelectStyle([...updateSelectStyle]);
  };

  const handleSelectCenter = async (list, item) => {
    setSelectCenter((selectCenter) => [...selectCenter, item]);
    await getAllBatchesByCenters([...selectCenter, item]);
  };
  const handaleSelectCenterDelete = async (list, item) => {
    // const updateSelectCenter = selectCenter.filter((_id) => _id !== item._id);
    setSelectCenter([...list]);
    console.log(selectCenter);
    await getAllBatchesByCenters([...list.map((c) => c._id)]);
  };

  const handleSelectBatch = (list, item) => {
    setSelectBatch((selectBatch) => [...selectBatch, item]);
  };
  const handaleSelectBatchDelete = (list, item) => {
    const updateSelectBatch = selectBatch.filter((i) => i._id !== item._id);
    setSelectBatch([...updateSelectBatch]);
  };

  useEffect(() => {
    loadInstructorData();
    loadCentersAndCourses();
  }, []);

  //get the course
  const loadInstructorData = async () => {
  	
    try {
      setLoading(true);
      const { data: instructorRecieved } = await axios.get(
        `/api/users/instructors/getAnInstructor?id=${props.id}`
      );
      console.log(instructorRecieved);
      setLoading(false);
      if (instructorRecieved) {
        setAddInstructor({
          name: instructorRecieved.instructor.userId.firstName,
          email: instructorRecieved.instructor.userId.email,
          shortDescription: instructorRecieved.instructor.shortDescription,
          longDescription: instructorRecieved.instructor.longDescription,
          phone: instructorRecieved.instructor.userId.phoneNumber,
          address: instructorRecieved.instructor.userId.address,
          password: '',
          image: '',
        });
        
        setImageInput(
          instructorRecieved.instructor.userId.profilePic
            ? instructorRecieved.instructor.userId.profilePic
            : ''
        );
        
        setSelectCenter(instructorRecieved.instructor.centers);
        setSelectBatch(instructorRecieved.instructor.batches);
        setSelectStyle(instructorRecieved.instructor.courses);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //get all centers
  const loadCentersAndCourses = async () => {
    try {
      setOnLoading(true);
      const { data: centersRecived } = await axios.get('/api/centers/getAllNameAddress');
      const { data: coursesRecived } = await axios.get('/api/courses/getAllName');

      setOnLoading(false);
      setCenterData([...centersRecived.centers]);
      setStyleData([...coursesRecived.courses]);
      console.log('style data', styleData);


    } catch (error) {
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };
  
  //get all batchs by centers
  const getAllBatchesByCenters = async (centers) => {
    try {
      const { data } = await axios.post('api/batches/getAllNameByFilters', {
        centers: [...centers],
      });
      setbatchData([...data.batches]);
    } catch (error) {
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //profile image
  const handelBannerImageInputChange = (e) => {
    const image1 = e.target.files[0];
    const reader1 = new FileReader();
    reader1.readAsDataURL(image1);
    reader1.onloadend = () => {
      setImageInput(reader1.result);
    };
    setImageUploaded(image1);
  };

  const deleteImage = () => {
    setImageInput('');
  };

  const uploadProfileImage = async (instructorData) => {
    const imageData = new FormData();
    const uniqueId = nanoid(5);

    imageData.append(
      'image',
      imageUploaded,
      `${instructorData.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}-profile-${uniqueId}`
    );
    await axios.post(`/api/users/instructors/upload-profile-image`, imageData, {
      headers: {
        headers: { 'Content-type': 'application/form-data' },
      },
    });

    instructorData.profilePic = `https://app-backend.dmentors.in/images/${instructorData.name
      .replace(/[^a-zA-Z0-9]/g, '')
      .toLowerCase()}-profile-${uniqueId}.jpg`;
  };

  //submit function
  const addInstructorHandler = async (e) => {
    e.preventDefault();
    const instructorData = {
      name,
      email,
      shortDescription,
      longDescription,
      phoneNumber: phone,
      address,
      password,
      courses: [...selectStyle.map((c) => c._id)],
      centers: [...selectCenter.map((c) => c._id)],
      batches: [...selectBatch.map((c) => c._id)],
    };

    if (
      instructorData.name &&
      instructorData.email &&
      instructorData.shortDescription &&
      instructorData.longDescription &&
      instructorData.phoneNumber &&
      instructorData.courses.length > 0 &&
      instructorData.centers.length > 0
    ) {
      try {
      	
        const phoneRegex = /^\d{10}$/;
        const emailRegex =
          /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
        if (!emailRegex.test(instructorData.email)) {
          throw new Error('Invalid email address');
        }
        if (!phoneRegex.test(instructorData.phoneNumber)) {
          throw new Error('Invalid phone number');
        }

        if (imageUploaded) {
          await uploadProfileImage(instructorData);
        }

        setOnLoading(true);
        const { data } = await axios.put(`/api/users/instructors/edit?id=${props.id}`, instructorData);
        setTimeout(() => {
          props.handleClose();
          props.editSuccess();
        }, 2000);
        setOnLoading(false);
        setSuccessSnackbar(true);
        console.log('Batch Data', data);
      } catch (error) {
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please fill all require fields');
      setErrorSnackbar(true);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Edit Instructor
        </DialogTitle>
        {loading ? (
          <CircularProgress style={{ position: 'relative', left: '45%', margin: '1em 0' }} />
        ) : (
          <DialogContent>
            <form onSubmit={(e) => addInstructorHandler(e)} autocomplete='off'>
              <div className='InstructorTopContent'>
                <div>
                  <p className='InstructorLable'>Name*</p>
                  <input
                    className='InstructorName'
                    type='text'
                    style={{ width: '15.6vw' }}
                    placeholder='Instructor name'
                    name='name'
                    value={name}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>

                <div>
                  <p className='InstructorLable'>Phone Number*</p>
                  <input
                    className='InstructorName'
                    type='text'
                    placeholder='Phone Number'
                    style={{ width: '15vw' }}
                    name='phone'
                    value={phone}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>

              <div className='InstructorTopContent'>
                <div>
                  <p className='InstructorLable'>Email*</p>
                  <input
                    className='InstructorName'
                    type='email'
                    placeholder='Enter Email'
                    name='email'
                    autocomplete='off'
                    style={{ width: '15vw' }}
                    value={email}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div>
                  <p className='InstructorLable'>Password*</p>
                  <input
                    className='InstructorName'
                    type='password'
                    style={{ width: '15vw' }}
                    placeholder='Password'
                    name='password'
                    value={password}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
              
              <div className='InstructorTopContent'>
              
                  <div>
                  <p className='InstructorLable'>Short Description*</p>
                  <textarea
                    className='InstructorName'
                    type='text'
                    placeholder='Short Description'
                    name='shortDescription'
                    value={shortDescription}
                    onChange={(e) => onInputChange(e)}
                  ></textarea>
                </div>
                
                 <div>
                  <p className='InstructorLable'>Long Description*</p>
                  <textarea
                    className='InstructorName'
                    type='text'
                    placeholder='Long Description'
                    name='longDescription'
                    value={longDescription}
                    onChange={(e) => onInputChange(e)}
                  ></textarea>
                </div>
                
               
              </div>
              
              
                            
                            
                            
              <div className='InstructorTopContent'>
                <div>
                  <p className='InstructorLable'>Select Centers*</p>
                  <Multiselect
                    options={centerData}
                    displayValue='centerName'
                    style={{ width: '15vw' }}
                    name='selectCenters'
                    selectedValues={selectCenter}
                    onSelect={handleSelectCenter}
                    onRemove={handaleSelectCenterDelete}
                  />
                </div>

                
              </div>
              <div className='InstructorTopContent'>
                <div>
                  <p className='InstructorLable'>Select styles taught*</p>
                  <Multiselect
                    options={styleData}
                    displayValue='courseName'
                    name='selectStyle'
                    selectedValues={selectStyle}
                    style={{ width: '15vw' }}
                    onSelect={handleSelectStyle}
                    onRemove={handaleSelectStyleDelete}
                  />
                </div>
                
              </div>
              <div className='InstructorTopContent'>
                <div>
                  <p className='InstructorLable'>Select batches teaching</p>
                  <Multiselect
                    options={batchData}
                    displayValue='batchName'
                    style={{ width: '15vw' }}
                    name='selectInstructor'
                    selectedValues={selectBatch}
                    onSelect={handleSelectBatch}
                    onRemove={handaleSelectBatchDelete}
                  />
                </div>
                <div>
                  <p className='InstructorLable'>Address*</p>
                  <input
                    className='InstructorName'
                    type='text'
                    style={{ width: '15vw' }}
                    placeholder='Enter Address'
                    name='address'
                    value={address}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
              <div className='InstructorTopContent'>
                {!imageInput ? (
                  <div className='overviewImgBanner uk-text-center'>
                    <input
                      type='file'
                      accept='image/*'
                      id='upload-image'
                      onChange={(e) => handelBannerImageInputChange(e)}
                      hidden
                    />
                    <label className='upload-image-box' for='upload-image'>
                      <i class='far fa-image fa-7x'></i>
                      <br></br>
                      <p>Upload profile Image</p>
                    </label>
                  </div>
                ) : (
                  <div className='image-container'>
                    <span>
                      <Cancel onClick={() => deleteImage()} />
                    </span>
                    <img alt='' className='' src={imageInput}></img>
                  </div>
                )}
              </div>

              <div className='instructorButton'>
                <button className='InstructorCancle' onClick={props.handleClose}>
                  Cancel
                </button>
                <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
              </div>
            </form>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={successSnackbar}
              autoHideDuration={1200}
              onClose={() => setSuccessSnackbar(false)}
            >
              <Alert severity='success' variant='filled'>
                Instructor Updated
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={errorSnackbar}
              autoHideDuration={1600}
              onClose={() => setErrorSnackbar(false)}
            >
              <Alert severity='error' variant='filled'>
                {errorMessage.substring(0, 50)}
              </Alert>
            </Snackbar>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
