import React, { useEffect, useState } from 'react';
import '../Pages.css';
import ReactDOM from 'react-dom';
import MUIDataTable from 'mui-datatables';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PaymentModal from './PaymentModal';
import { CircularProgress } from '@material-ui/core';
import AddInstructor from './AddInstructor';
import EditInstructor from './EditInstructor';
import DeleteInstructor from './DeleteInstructor';
import axios from 'axios';
import { Delete } from '@material-ui/icons';
import { useHistory } from "react-router-dom";

export default function InstructorTable(props) {
	
  const [selectedInstructor, setSelectedInstructor] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [instructorData, setInstructorData] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };
  
   const handleClickOpenPayment = () => {
    setOpenPayment(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
   const handleClosePayment = () => {
    setOpenPayment(false);
  };

  const columns = [
    {
      name: 'ID',
      options: {
        display: false,
      },
    },
    'Instructor',
    'Centers',
    'Styles',
    'Batches',    
     {
          label: "Attendance",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <button className="ViewTableButton" onClick={() => history.push('/instructor-attendance/'+value) }>
                    View
                  </button>
              )
          }
         }
     },
     {
          label: "Payments",
          options: {
          customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <button className="ViewTableButton" onClick={() => history.push('/instructor-payments/'+value) }>
                    View
                  </button>
              )
          }
         }
     },
    {
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                className='EditTableButton'
                style={{fontSize:11}}
                onClick={() => {
                  setSelectedInstructor(tableMeta.rowData[0]);
                  setOpenEdit(true);
                }}
              >
                View | Edit
              </button>
              <button
                className='deleteIcon'
                onClick={() => {
                  setSelectedInstructor(tableMeta.rowData[0]);
                  setOpenDelete(true);
                }}
              >
                <Delete />
              </button>
            </>
          );
        },
      },
    },
  ];

  useEffect(() => {
    loadCenters();
  }, [addSuccess]);

  const loadCenters = async () => {
    try {
      console.log('instructor datasss');
      setOnLoading(true);
      const { data } = await axios.get('/api/users/instructors/getAll');
      console.log('instructor data', data.instructors);
      setOnLoading(false);
      setInstructorData(data.instructors);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      // console.log(error.data);
      setOnLoading(false);
    }
  };
  return (
    <React.Fragment>


      {open && (
        <AddInstructor handleClose={handleClose} 
                       open={open} 
                       addSuccess={() => setAddSuccess(!addSuccess)} />
      )}
      
     
      
      
      {openEdit && (
        <EditInstructor
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          id={selectedInstructor}
          editSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}

      {openDelete && (
        <DeleteInstructor
          handleClose={() => setOpenDelete(false)}
          open={openDelete}
          instructor={selectedInstructor}
          deleteSuccess={() => setAddSuccess(!addSuccess)}
        />
      )}
      <div className='AddButtonPosition'>
        <div className='tableHeader'>INSTRUCTORS</div>
        <button className='AddButton' 
          style={{fontSize:13,}}
          onClick={handleClickOpen}>
          Add Instructor
        </button>
        
        
      </div>
      <div style={{ marginLeft: '1%', marginRight: '1%' }}>
        {onLoading ? (
          <CircularProgress style={{ position: 'absolute', left: '55%', top: '50%' }} />
        ) : (
          <MUIDataTable
            data={instructorData.map((instructor, i) => {
              return [
                instructor._id,
                instructor.name,
                instructor.center,
                instructor.courses,
                instructor.batches,
                instructor._id,
                instructor._id,
              ];
            })}
            columns={columns}
            options={{
              selectableRows: false,
              rowsPerPageOptions: [],
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
