import React, { useEffect, useState } from 'react';
import '../Pages.css';
import Dialog from '@material-ui/core/Dialog';
import { Multiselect } from 'multiselect-react-dropdown';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

let centerData = [];

export default function AlertDialogSlide(props) {
	
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [centers, setCenters] = useState([]);
  const [instructorsData, setInstructorsData] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [name, setName] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [onLoading, setOnLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCourse();
    loadCenters();
  }, []);

  //get the course
  const loadCourse = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/courses/getACourse?id=${props.id}`);

      setLoading(false);
      if (data) {
        setName(data.course.courseName);
        setCourseDescription(data.course.courseDescription);
        setCenters(data.course.centers);
        setInstructors([
          ...data.course.instructors.map((i) => {
            return {
              _id: i._id,
              name: i.userId.firstName,
            };
          }),
        ]);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //get all  centers
  const loadCenters = async () => {
    try {
      const { data } = await axios.get('/api/centers/getAllNameAddress');
      centerData = [...data.centers];
    } catch (error) {
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  //get all instructors by centers
  const getAllInstructorsByCenters = async (centers) => {
    try {
      const { data } = await axios.post('api/users/instructors/getAllNameByFilters', {
        centers: [...centers],
      });
      setInstructorsData([...data.instructors]);
    } catch (error) {
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  const handleCenterChange = async (list, item) => {
  	

    setCenters((centers) => [...centers, item]);
    setInstructors([]);
    setInstructorsData([]);
    await getAllInstructorsByCenters([...centers, item._id]);
  };
  
  const handaleCenterDelete = async (list, item) => {
    const updatedCenters = centers.filter((c) => c._id !== item._id);
    setCenters([...updatedCenters]);
    setInstructors([]);
    setInstructorsData([]);
    await getAllInstructorsByCenters(updatedCenters);
  };
  const handleInstructorChange = (list, item) => {
    console.log('Batch Instructor', item);
    setInstructors((instructors) => [...instructors, item]);
  };
  const handaleInstructorDelete = (list, item) => {
    const UpdatedInstructor = instructors.filter((_id) => _id !== item._id);
    setInstructors([...UpdatedInstructor]);
  };

  //submit function
  const editCoursesHandler = async (e) => {
    e.preventDefault();
    const courseData = {
      courseName: name,
      courseDescription: courseDescription,
      centers: [...centers.map((i) => i._id)],
      instructors: [...instructors.map((i) => i._id)],
    };
    
    
    if (courseData.courseName && courseData.courseDescription && courseData.centers.length > 0) {
    	
      try {
        setOnLoading(true);
        
        const { data } = await axios.put(`/api/courses/edit?id=${props.id}`, courseData);

        setTimeout(() => {
          props.handleClose();
          props.editSuccess();
        }, 1000);

        setOnLoading(false);
        setSuccessSnackbar(true);
      } catch (error) {
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please fill all require fields');
      setErrorSnackbar(true);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Edit Course / Style
        </DialogTitle>
        {loading ? (
          <CircularProgress style={{ position: 'relative', left: '45%', margin: '1em 0' }} />
        ) : (
          <DialogContent className='coursesModal'>
            <form onSubmit={(e) => editCoursesHandler(e)}>
              <div>
                <p className='InstructorLable'>Name*</p>
                <input
                  style={{ width: '95%' }}
                  type='text'
                  className='InstructorName'
                  placeholder='Style Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              
              
              <div>
              <p className='InstructorLable'>Description*</p>
              <textarea
                style={{ width: '95%', height:100 }}
                multiLine={true}
                type='text'
                className='InstructorName'
                placeholder='Course/Style Description'
                value={courseDescription}
                onChange={(e) => setCourseDescription(e.target.value)}
              >
              
              </textarea>
            </div>
            
            
              <div>
                <p className='InstructorLable'>Centers*</p>
                <Multiselect
                  options={centerData}
                  displayValue='centerName'
                  selectedValues={centers}
                  onSelect={handleCenterChange}
                  onRemove={handaleCenterDelete}
                />
              </div>

              <div>
                <p className='InstructorLable'>Instructors</p>
                <Multiselect
                  style={{ width: '100%' }}
                  options={instructorsData}
                  selectedValues={instructors}
                  displayValue='name'
                  onSelect={handleInstructorChange}
                  onRemove={handaleInstructorDelete}
                />
              </div>
              <div className='instructorButton' style={{ marginTop: '15vh' }}>
                <button className='InstructorCancle' onClick={props.handleClose}>
                  Cancel
                </button>
                <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
              </div>
            </form>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={successSnackbar}
              autoHideDuration={1200}
              onClose={() => setSuccessSnackbar(false)}
            >
              <Alert severity='success' variant='filled'>
                Course Updated
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={errorSnackbar}
              autoHideDuration={1600}
              onClose={() => setErrorSnackbar(false)}
            >
              <Alert severity='error' variant='filled'>
                {errorMessage}
              </Alert>
            </Snackbar>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
