import React, { useEffect, useState } from 'react';
import '../Pages.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const [successSnackbar, setSuccessSnackbar] = useState(false);

  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [center, setCenter] = useState({
    name: '',
    address: '',
  });
  const [onLoading, setOnLoading] = useState(false);

  const { name, address } = center;
  const onInputChange = (e) => {
    setCenter({ ...center, [e.target.name]: e.target.value });
  };

  const editCentersHandler = async (e) => {
    e.preventDefault();
    try {
      setOnLoading(true);
      const { data } = await axios.put(`api/centers/edit?id=${props.center[0]}`, {
        centerName: name,
        address,
      });

      setOnLoading(false);
      setSuccessSnackbar(true);
      setTimeout(() => {
        props.handleClose();
        props.editSuccess();
      }, 2000);
    } catch (error) {
      console.log(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      // console.log(error.data);
      setOnLoading(false);
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
      setErrorSnackbar(true);
    }
  };

  useEffect(() => {
    setCenter({
      name: props.center[1],
      address: props.center[2],
    });
  }, [props]);

  console.log(props.center);

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Edit Center
        </DialogTitle>
        <DialogContent>
          <>
            <div>
              <p className='InstructorLable'>Center Name</p>
              <input
                style={{ width: '95%' }}
                className='InstructorName'
                type='text'
                placeholder='Center 01'
                name='name'
                value={name}
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div>
              <p className='InstructorLable'>Address</p>
              <textarea
                type='text'
                className='InstructorName'
                placeholder='Address'
                style={{
                  width: '95%',
                  paddingTop: '5px',
                }}
                rows='2'
                name='address'
                value={address}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </>
          <div className='instructorButton'>
            <button className='InstructorCancle' onClick={props.handleClose}>
              Cancel
            </button>
            <button onClick={(e) => editCentersHandler(e)} className='InstructorSave'>
              {onLoading ? <CircularProgress /> : 'Save'}
            </button>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Center Updated
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
}
