import React, { useEffect, useState } from 'react';
import '../Pages.css';
import { Multiselect } from 'multiselect-react-dropdown';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { Slide, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { VpnLockSharp } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AddGalleryVideo(props) {
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [courseData, setCourseData] = useState([]);
  // const [instructorData, setInstructorData] = useState([]);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [course, setCourse] = useState('');
  // const [instructor, setInstructor] = useState('');
  const [onLoading, setOnLoading] = useState(false);

  useEffect(() => {
    loadVideo();
  }, []);

  console.log('outside', course);

  // //get all  instructors by center
  // const loadInstructorsByCourse = async (course) => {
  //   try {
  //     const { data: instructorsRevived } = await axios.post('/api/users/instructors/getAllNameByFilters', {
  //       course: course,
  //     });

  //     setInstructorData([...instructorsRevived.instructors]);
  //   } catch (error) {
  //     setErrorMessage(
  //       error.response && error.response.data.message ? error.response.data.message : error.message
  //     );
  //   }
  // };

  // //get all  courses
  // const loadCourses = async () => {
  //   try {
  //     const { data: coursesRecived } = await axios.get('/api/courses/getAllName');
  //     setCourseData([...coursesRecived.courses]);
  //   } catch (error) {
  //     setErrorMessage(
  //       error.response && error.response.data.message ? error.response.data.message : error.message
  //     );
  //   }
  // };

  //load video
  const loadVideo = async () => {
    try {
      const { data } = await axios.get(`/api/galleryvideos/?id=${props.id}`);
      setVideoTitle(data.galleryVideo.videoTitle);
      setVideoURL(data.galleryVideo.videoURL);
      // setCourse(data.galleryVideo.course);
      // setInstructor(data.galleryVideo.instructor);
    } catch (error) {
      setErrorMessage(
        error.response && error.response.data.message ? error.response.data.message : error.message
      );
    }
  };

  //submit function
  const EditGalleryVideo = async (e) => {
    e.preventDefault();
    const videoData = {
      videoTitle,
      videoURL,
    };

    if ((videoData.videoTitle, videoData.videoURL)) {
      try {
        const ytURLRegEX =
          /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

        if (!videoData.videoURL.match(ytURLRegEX)) {
          throw new Error('The video URL must be a valid Youtube video url');
        }
        setOnLoading(true);
        const { data } = await axios.put(`/api/galleryvideos/?id=${props.id}`, videoData);
        setTimeout(() => {
          props.handleClose();
          props.editSuccess();
        }, 2000);
        setOnLoading(false);
        setSuccessSnackbar(true);
      } catch (error) {
        setOnLoading(false);
        setErrorMessage(
          error.response && error.response.data.message ? error.response.data.message : error.message
        );
        setErrorSnackbar(true);
      }
    } else {
      setErrorMessage('Please fill all require fields');
      setErrorSnackbar(true);
    }
  };

  // const handleCourseChange = async (e) => {
  //   setCourse(e.target.value);
  //   await loadInstructorsByCourse(e.target.value);
  //   instructorData.length > 0 && !instructor && setInstructor(instructorData[0]._id);
  // };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title' className='AddEditInstructor'>
          Edit Gallery Video
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => EditGalleryVideo(e)}>
            <div className='InstructorTopContent'>
              <div style={{ width: '100%' }}>
                <p className='InstructorLable'>Video Title*</p>
                <input
                  className='InstructorName'
                  type='text'
                  style={{ width: '100%' }}
                  placeholder='Enter Video Title'
                  name='name'
                  value={videoTitle}
                  onChange={(e) => setVideoTitle(e.target.value)}
                />
              </div>
            </div>
            <div>
              <p className='InstructorLable'>Video URL*</p>
              <input
                className='InstructorName'
                type='text'
                style={{ width: '100%' }}
                placeholder='Enter Video URL'
                name='name'
                value={videoURL}
                onChange={(e) => setVideoURL(e.target.value)}
              />
            </div>
            {/* <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Select Course*</p>
                <select
                  className='InstructorName'
                  style={{ width: '20vw' }}
                  value={course}
                  onChange={handleCourseChange}
                >
                  {courseData.map((course) => (
                    <option value={course._id}>{course.courseName}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className='InstructorTopContent'>
              <div>
                <p className='InstructorLable'>Select Instructor</p>
                <select
                  className='InstructorName'
                  style={{ width: '20vw' }}
                  value={instructor}
                  onChange={(e) => setInstructor(e.target.value)}
                >
                  {instructorData.map((instructor) => (
                    <option value={instructor._id}>{instructor.name}</option>
                  ))}
                </select>
              </div>
            </div> */}

            <div className='instructorButton'>
              <button className='InstructorCancle' onClick={props.handleClose}>
                Cancel
              </button>
              <button className='InstructorSave'>{onLoading ? <CircularProgress /> : 'Save'}</button>
            </div>
          </form>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={successSnackbar}
            autoHideDuration={1200}
            onClose={() => setSuccessSnackbar(false)}
          >
            <Alert severity='success' variant='filled'>
              Video Updated
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={errorSnackbar}
            autoHideDuration={1600}
            onClose={() => setErrorSnackbar(false)}
          >
            <Alert severity='error' variant='filled'>
              {errorMessage.substring(0, 50)}
            </Alert>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </div>
  );
}
